import { DeleteOutlineOutlined, Edit } from "@mui/icons-material";
import { Grid, IconButton, LinearProgress } from "@mui/material";
import { useState } from "react";
import { useAppContext } from "../../../../../../auth/appContext";
import { storage } from "../../../../../../firebase";
import { Contact } from "../../../../../../interfaces";
import EditInstructorsView from "./view";
import { ref } from "firebase/storage";
import { getDownloadUrl } from "../../../../../../helpers";

interface Props {
    instructors: Contact[];
}
const EditInstructors: React.FC<Props> = ({ instructors }) => {
    const [contact, setContact] = useState<Contact>();
    const [pageSize, setPageSize] = useState<number>(25);
    const [openDelete, setOpenDelete] = useState<string>('');
    const { AppSettings } = useAppContext();

    const columns: any[] = [
        { field: 'Name', headerName: 'Name', flex: 1, renderCell: (params: any) => params.row.Name },
        { field: 'Region', headerName: 'Region', flex: 0.5 },
        { field: 'Email', headerName: 'Email', flex: 1 },
        { field: 'Tel', headerName: 'Teléfono', flex: 1 },
        {
            field: 'id', headerName: ' ', align: 'center', flex: 0.5, renderCell: (params: any) =>
                <>
                    <IconButton color="secondary" onClick={() => setContact(params.row)}>
                        <Edit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => { setOpenDelete(params.row.ID) }}>
                        <DeleteOutlineOutlined />
                    </IconButton>
                </>
        },
    ];

    /**
     * Descarga de plantilla
     */
    const downloadTemplate = async () => {
        const fileRef = ref(storage, `Clients/${AppSettings.Name}/FileTemplates/plantilla_instructores.xlsx`);
        getDownloadUrl(fileRef.fullPath)
            .then((url) => {
                const link = document.createElement("a");
                link.download = `plantilla_instructores.xlsx`;
                link.href = url;
                link.click();
            })
            .catch((error) => {
                alert("Error descargando el archivo")
                console.error("Error al obtener la URL de descarga:", error);
            });
    }


    return (
        columns ? (
            <EditInstructorsView
                columns={columns}
                pageSize={pageSize}
                setPageSize={setPageSize}
                instructor={contact}
                setInstructor={setContact}
                downloadTemplate={downloadTemplate}
                instructors={instructors}
                openDelete={openDelete}
                setOpenDelete={setOpenDelete}
            />
        )
            : (
                <Grid container justifyContent="center">
                    <LinearProgress
                        color="secondary"
                        sx={{ width: "75%" }}
                    />
                </Grid>
            )
    )
}
export default EditInstructors;