import { ArrowBack } from "@mui/icons-material";
import { Divider, Fade, Grid, IconButton, Paper, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CertificationsTable from "../CertificationsTable";
import EditInstructors from "./EditInstructors";
import useStyles from '../style'
import StudyTips from "./StudyTips";
import { Contact } from "../../../../../interfaces";
import { CustomTabPanel, a11yProps } from "../../../../../components/CustomTabPanel";

interface Props {
    content: string;
    handleChange: (event: React.SyntheticEvent, newValue: string) => void;
    isMobile: boolean;
    setEdit: (event: boolean) => void;
    fade: boolean;
    instructors: Contact[];
}

const EditAnnonuncementsView: React.FC<Props> = ({ content, handleChange, isMobile, setEdit, fade, instructors }) => {
    const classes = useStyles();
    const [showButton, setShowButton] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<number>(0);

    useEffect(() => {
        if (fade)
            setShowButton(true)
        else
            setShowButton(false)
    }, [fade]);

    return (
        <Fade in={fade} className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container alignItems='center'>
                            <Grid item xs={12} sx={{ px: 1 }}>
                                <Grid container justifyContent='flex-end' spacing={2}>
                                    <Grid item xs={4} container alignItems='center'>
                                        <IconButton
                                            edge="end"
                                            onClick={() => setEdit(false)}>
                                            <ArrowBack />
                                        </IconButton>
                                        &emsp;
                                        <Typography variant="h5">
                                            Edición de anuncios
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8} container justifyContent='flex-end'>
                                        <Tabs
                                            value={selectedTab}
                                            onChange={(e, newVal: number) => {
                                                setSelectedTab(newVal)
                                            }}
                                            textColor="inherit"
                                            indicatorColor="secondary"
                                        >
                                            <Tab
                                                label="tips de estudio"
                                                {...a11yProps(0)}
                                            />
                                            <Tab
                                                label="instructores"
                                                {...a11yProps(1)}
                                            />
                                            <Tab
                                                label="fechas de certificación"
                                                {...a11yProps(2)}
                                            />
                                        </Tabs>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 1.5, pb: 1 }}>
                                    <CustomTabPanel
                                        value={selectedTab}
                                        index={0}
                                    >
                                        <Grid item xs={12}>
                                            <StudyTips />
                                        </Grid>
                                    </CustomTabPanel>
                                    <CustomTabPanel
                                        value={selectedTab}
                                        index={1}
                                    >
                                        <EditInstructors
                                            instructors={instructors}
                                        />
                                    </CustomTabPanel>
                                    <CustomTabPanel
                                        value={selectedTab}
                                        index={2}
                                    >
                                        <CertificationsTable
                                            showButton={showButton}
                                        />
                                    </CustomTabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Fade>
    )

}
export default EditAnnonuncementsView;