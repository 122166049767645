import { EmojiEvents, WorkspacePremiumOutlined } from "@mui/icons-material";
import { Fade, Grid, lighten, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ReactConfetti from "react-confetti";
import { useAppContext } from "../../../../../../../auth/appContext";
import { Reta } from "../../../../../../../interfaces/reta";
import useStyles from "./style";


interface Props {
    quiz: Reta;
    selectPregunta: (e: number) => void;
    selected: number;
    finished: boolean;
    idInscrito: string;
    resultados: any[];
}

const GameQuiz: React.FC<Props> = ({ quiz, selectPregunta, selected, finished, idInscrito, resultados }) => {
    const { AppSettings } = useAppContext();
    const classes = useStyles();

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    /**
     * Obtener color de medalla
     * @param pos numero de posición
     * @returns color de medalla o trofeo
     */
    const getBadgeColor = (pos: number) => {
        switch (pos) {
            case 1:
                return 'goldenrod';
            case 2:
                return 'silver'
            case 3:
                return 'brown'
            default:
                return ''
        }
    }


    return (
        <Fade in={true} timeout={400}>
            <Grid container maxWidth='xs'>
                {
                    quiz.realtimeOpts && quiz.realtimeOpts.map((option, index) => {
                        return (
                            <Grid item key={`opt-${index}`} xs={12}
                                sx={{ padding: 1, }}
                                onClick={() => { selectPregunta(index) }}
                            >
                                <Paper
                                    sx={{ minHeight: '150px', display: 'flex', textAlign: 'center', justifyContent: 'center', alignItems: 'center', padding: '10px', cursor: 'pointer', }}
                                    className={index === selected ? classes.selected : ""}
                                >
                                    <Typography variant='h5'>{option.length > 50 ? `${option.slice(0, 50)}...` : option}</Typography>
                                </Paper>
                            </Grid>
                        )
                    })
                }
                {finished &&
                    <Fade in={true} timeout={400}>
                        <Grid container maxWidth="xs">
                            <Grid item xs={12} justifyContent='center' container maxWidth="xs">
                                <Typography variant="h5">Tu posición fue:</Typography>
                            </Grid>
                            {
                                resultados.map((participante, index) => (
                                    participante.id === idInscrito ?
                                        (<Fade in={true} timeout={2500} key={index + 1}>
                                            <Grid container sx={{ marginTop: 2 }} key={index} maxWidth="xs">
                                                <Grid item xs={12} key={index} justifyContent="center">
                                                    <Typography variant="h4" align="center">
                                                        ¡{index + 1} lugar!
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} container justifyContent='center'>
                                                    {index + 1 < 4 ?
                                                        <>
                                                            <ReactConfetti
                                                                width={windowDimensions.width}
                                                                height={windowDimensions.height}
                                                                colors={[AppSettings.DesignSettings.Palette['primary']['main'], AppSettings.DesignSettings.Palette['secondary']['main']]}
                                                                numberOfPieces={100}
                                                            />
                                                            <ReactConfetti
                                                                width={windowDimensions.width}
                                                                height={windowDimensions.height}
                                                                colors={[AppSettings.DesignSettings.Palette['primary']['main'], AppSettings.DesignSettings.Palette['secondary']['main']]}
                                                                numberOfPieces={100}
                                                                drawShape={ctx => {
                                                                    ctx.beginPath()
                                                                    for (let i = 0; i < 22; i++) {
                                                                        const angle = 0.35 * i
                                                                        const x = (0.2 + (1.5 * angle)) * Math.cos(angle)
                                                                        const y = (0.2 + (1.5 * angle)) * Math.sin(angle)
                                                                        ctx.lineTo(x, y)
                                                                    }
                                                                    ctx.stroke()
                                                                    ctx.closePath()
                                                                }}
                                                            />
                                                            <EmojiEvents sx={{ width: "150px", height: "150px", color: getBadgeColor(index + 1), borderColor: '#000000' }} />
                                                        </>
                                                        :
                                                        <WorkspacePremiumOutlined sx={{ width: "150px", height: "150px", color: lighten('#964B00', 0.3), borderColor: '#000000' }} />
                                                    }
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="h5" align="center">
                                                        {index + 1 < 4 ? "¡Felicidades!" : "¡Sigue esforzandote, tu puedes!"}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Fade>)

                                        : (null)
                                ))
                            }
                        </Grid>
                    </Fade>
                }
            </Grid>
        </Fade>
    )
}
export default GameQuiz;