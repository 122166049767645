import { GridSelectionModel } from "@mui/x-data-grid";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  QuerySnapshot,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../auth/appContext";
import { useDataContext } from "../../auth/dataContext";
import withAuth from "../../auth/withAuth";
import BackdropComponent from "../../components/Backdrop";
import { firestore } from "../../firebase";
import { saveAnalytics } from "../../helpers/Analytics";
import { User } from "../../interfaces";
import {
  AssignmentV2,
  ModuleProgress,
  TopicProgress,
} from "../../interfaces/assignment";
import { Course, Module } from "../../interfaces/course";
import {
  Content,
  StudyPlan,
  StudyPlanAssignment,
} from "../../interfaces/studyplans";
import { AssignmentUI, Courses, StudyPlans } from "../../interfaces/ui";
import * as StudyPlansPageHub from "./Instances";

const StudyPlansPage: React.FC = () => {
  const { AppSettings } = useAppContext();
  const { state, dispatch } = useDataContext();
  const { id } = useParams();

  const [coursesCollection, setCoursesCollection] = useState<Course[]>([]); //lista de cursos (todos)
  const [studyPlan, setStudyPlan] = useState<StudyPlan>();
  const [loading, setLoading] = useState<boolean>(true);
  const [msg, setMsg] = useState<string>("");
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const [selectedCourses, setSelectedCourses] = useState<any>({});
  const [selectedIDCourses, setSelectedIDCourses] =
    useState<GridSelectionModel>([]); //cursos seleccionados en el dataGrid
  const [alertPublish, setAlertPublish] = useState<boolean>(false); //para mostrar si falla la validación antes de publicar
  const [alertMessage, setAlertMessage] = useState<string>(""); //mostrar la falla de la validación
  const [assignment, setAssignment] = useState<AssignmentUI>({
    progress: 0,
  });
  const [enrolling, setEnrolling] = useState<boolean>(false);
  //para que cuando haga el setup de los cursos, no se marque que hay cambios en el StudyPlan
  //true: el StudyPlan traia Content y va a hacer setup, no marca hasChanges, la bandera se cambia a false en el useEffect, una vez hecho el setup
  //false: el StudyPlan no traia Content y cuando el Content cambie, marcará hasChanges
  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // UPDATE UI INFO, PROGRESO PLAN DE ESTUDIO Y CURSOS
  useEffect(() => {
    // si el usuario está siguiendo el plan
    if (studyPlan && assignment.assignment?.TakenByUser) {
      //progreso del plan
      let progressPlan = 0;
      // progreso de cada curso, aux para las tarjetas de vista
      let progressCourses: any = {};

      // los recorre por prioridad
      studyPlan.Content?.forEach(({ idCourse }) => {
        // progreso de cada curso para las tarjetas
        let progress = 0;
        // encontrar assignment del curso del plan de estudio
        let a = state.assignments2?.find(
          (as) => as.Course === idCourse && as.User === state.user.UID
        );

        // si existe, el usuario está inscrito al curso
        if (a) {
          // calcular avance del curso
          progress = a.Progress.reduce((sum, item) => {
            return sum + item.ModuleProgress;
          }, 0);
          progress /= a.Progress.length > 0 ? a.Progress.length : 1;
          progress = Number((progress * 100).toFixed(2));

          if (progress === 100) {
            progressPlan += selectedCourses[idCourse].ApproximateDuration; //en minutos
          }

          progressCourses[idCourse] = progress;
        }
      });

      // assigno el progress
      progressPlan = Number(
        ((progressPlan / studyPlan!.ApproximateDuration) * 100).toFixed(2)
      );
      setAssignment({
        ...assignment,
        progress: progressPlan,
        auxProgressCourse: progressCourses,
      });
    }
  }, [assignment?.assignment, studyPlan]);

  // set up plan de estudio
  useEffect(() => {
    if (id) {
      getCourses();
      const studyPlan = doc(
        firestore,
        `Instances/${AppSettings.Name}/StudyPlans/${id}`
      );
      getDoc(studyPlan)
        .then(async (snap) => {
          //El plan consultado existe?
          if (snap.exists()) {
            // Consulta exitosa, plan existe
            const data = snap.data() as StudyPlan;
            setStudyPlan(data);
            setFlag(data.Content.length > 0);

            // consultar si el usuario en cuestión, tiene asignado este plan de estudio

            let tempSPA = state.studyPlansAssignments!.find(
              (el) => el.StudyPlan === data.ID
            );

            setAssignment({ ...assignment, assignment: tempSPA });

            setLoading(false);
          } else {
            //Curso no existe
            setMsg("Curso no existe");
            setLoading(false);
          }
        })
        .catch((e) => {
          //No permission
          setMsg("Error, no tienes permisos?");
          console.log(e);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setMsg("Esto no debería de pasar");
    }
  }, []);

  /** SET CONTENT */
  useEffect(() => {
    if (selectedIDCourses.length > 0 && studyPlan) {
      // Obtener objetos Course de los IDS seleccionados
      let tempSelectedCourses: any = {};
      selectedIDCourses.forEach((id) => {
        let temp = coursesCollection.find((course) => course.ID === id);

        if (temp) {
          tempSelectedCourses[temp.ID!] = temp;
        }
      });

      // SET CONTENT
      let oldContent = [...studyPlan.Content];
      let tempContent: Content[] = [];
      let aproxDur = 0;

      // recorrer los cursos seleccionados
      Object.keys(tempSelectedCourses).map((id, index) => {
        // verificar si ese curso ya estaba antes del cambio
        let tempOld = oldContent.find((elemento) => elemento.idCourse === id);

        // si el curso estaba, mantener su prioridad y su posición en el arreglo
        // NOTA, puede causar algo como content[1] = undefined, se limipia abajo
        if (tempOld) {
          tempContent[tempOld.priority] = tempOld;
        } else {
          // si no estaba, asignarle como prioridad el index
          tempContent[index] = {
            idCourse: id,
            priority: index,
          };
        }

        // sumar duraciones aproxs
        aproxDur += tempSelectedCourses[id].ApproximateDuration;
      });

      // filtrar los !undefined
      tempContent = tempContent.filter((element) => {
        return element !== undefined;
      });

      // aquí se tiene algo como content[1] = {..., prioridad: 2} y pasa a content[1] = {..., prioridad: 1}
      // "recorrer" las prioridades/igualarlas al index
      let contentClean: Content[] = [];
      tempContent.forEach((element, index) => {
        contentClean.push({
          idCourse: element.idCourse,
          priority: index,
        });
      });

      let newSP: StudyPlan = {
        ...studyPlan,
        Content: contentClean,
        ApproximateDuration: aproxDur,
      };

      // set nuevos valores
      if (flag) {
        // es setup, no marca que hay cambios
        setStudyPlan(newSP);
        setFlag(false);
      } else {
        // marca que hay cambios en el método update
        update(newSP);
      }

      setSelectedCourses(tempSelectedCourses);
    }
  }, [selectedIDCourses]);

  // set up ID cursos seleccionados (datagrid)
  useEffect(() => {
    if (studyPlan && coursesCollection.length > 0) {
      if (selectedCourses.length <= 0 || selectedIDCourses.length <= 0) {
        let tempSelected: GridSelectionModel = [];

        studyPlan.Content.forEach((element) => {
          tempSelected.push(element.idCourse);
        });

        setSelectedIDCourses(tempSelected);
      }
    }
  }, [coursesCollection, studyPlan]);

  /**
   * Se obtienen los cursos de la base de datos y se filtran por los que están
   * únicamente activos
   */
  const getCourses = async () => {
    const courseRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/Courses`
    );
    const courseQuery = await getDocs(courseRef);
    if (!courseQuery.empty) {
      const courses = courseQuery.docs.map((u) => u.data() as Course);
      setCoursesCollection(courses.filter((c) => !c.isDraft && !c.isArchived));
    }
  };

  // guardar plan de estudio en BD
  const saveStudyPlan = async (newSP: StudyPlan) => {
    const spRef = doc(
      firestore,
      `Instances/${AppSettings.Name}/StudyPlans/${studyPlan?.ID}`
    );
    setDoc(spRef, newSP)
      .then((res) => {
        setHasChanges(false);
      })
      .catch((e) => {
        setMsg("Error, no tienes permisos?");
        console.log(e);
      });
  };

  // elimina el plan de estudio
  const deleteStudyPlan = async () => {
    const spRef = doc(
      firestore,
      `Instances/${AppSettings.Name}/StudyPlans/${studyPlan?.ID}`
    );
    await deleteDoc(spRef);
  };

  /**
   * Actualiza en DB y luego en el state, que el plan de estudio se ha publicado (isDraft = false)
   */
  const publishPlan = () => {
    //si regresa true es porque las validaciones pasaron
    if (checkIfPublish()) {
      //se cambia el estado para que no se muestre la alerta
      setAlertPublish(false);
      let publishSP: StudyPlan = { ...studyPlan!, isDraft: false };
      saveStudyPlan(publishSP).then(() => {
        update(publishSP);
      });
    } else {
      //Se cambia el estado para mostrar la alerta de que el curso no se puede publicar
      setAlertPublish(true);
    }
  };

  // verificar si el curso se puede publicar
  const checkIfPublish = () => {
    let check = true;
    if (studyPlan) {
      if (studyPlan.Content.length === 0) {
        setAlertMessage("El plan de estudio no tiene cursos");
        check = false;
      }

      if (studyPlan.Name === "") {
        setAlertMessage("El plan de estudio no tiene nombre");
        check = false;
      }
    } else {
      setAlertMessage("Ocurrió un error, intente después.");
      check = false;
    }

    return check;
  };

  // actualizar el plan de estudio en cualquier onChange
  const update = (updateStudyPlan: StudyPlan) => {
    setStudyPlan(updateStudyPlan);
    !hasChanges && setHasChanges(true);
    alertPublish && setAlertPublish(false);
  };

  // archivar plan de estudio
  const archive = () => {
    let archived: StudyPlan = { ...studyPlan!, isArchived: true };
    saveStudyPlan(archived).then(() => {
      update(archived);
    });
  };

  // el usuario da clic en "seguir plan de estudio"
  const seguirPlan = async () => {
    setEnrolling(true);
    // inscribir al usuario en todos los cursos
    let lists = await enrollAllCourses();
    // configurar el study plan assignment
    let listsSP = await configureSPassignment();

    // actualizar contexto
    dispatch({
      type: "set",
      values: {
        ...state,
        studyPlansAssignments: listsSP.SPA,
        studyPlans: listsSP.SP,
        courses: lists.courses,
        assignments2: lists.assignments,
      },
    });

    saveAnalytics(
      AppSettings.Name,
      state.user.UID!,
      `enroll:studyPlan/${studyPlan!.ID}`
    );
  };

  // CONFIGURAR EL STUDY PLAN ASSIGNMENT
  const configureSPassignment = async () => {
    // para actualizar el contexto
    let newStudyPlanAs = [...state.studyPlansAssignments!];
    let newSP = { ...state.studyPlans };

    // verificar si el assignment ya existía
    let assignmentExiste = state.studyPlansAssignments!.findIndex(
      (ass) => ass.StudyPlan === studyPlan?.ID
    );

    // para actualizar/crear assignment
    let newAssignment: StudyPlanAssignment | undefined = undefined;

    // YA habia un assignment, un eroller sugirió el plan de estudio, y el usuario va a seguirlo...
    if (assignmentExiste !== -1) {
      if (!state.studyPlansAssignments![assignmentExiste].TakenByUser) {
        //actualizar progreso
        newAssignment = {
          ...state.studyPlansAssignments![assignmentExiste],
          TakenByUser: true,
        };

        // subir a BD los cambios
        await saveSPassignment(newAssignment!);

        // actualizar context
        newStudyPlanAs![assignmentExiste] = newAssignment;
      }
    } else {
      // el usuario va a tomar el plan por si solo
      //crear nueva assignment
      newAssignment = {
        ID: "nuevoID",
        StudyPlan: studyPlan!.ID!,
        User: state.user.UID!,
        UserMail: state.user.Email!,
        Data: {},
        TakenByUser: true,
      };

      // subir a BD los cambios
      let newID = await createSPassignment(newAssignment);

      // actualizar context
      newSP[studyPlan!.ID!] = studyPlan!;
      newStudyPlanAs.push({ ...newAssignment, ID: newID });
    }

    return { SP: newSP, SPA: newStudyPlanAs };
  };

  const createSPassignment = async (newAssignment: StudyPlanAssignment) => {
    let newID = newAssignment.ID!;

    //Referencia a Assignments
    const studyPlanAssignmentRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/StudyPlansAssignments`
    );

    await getDocs(studyPlanAssignmentRef)
      .then(async (res) => {
        const newStudyPlanRef = await addDoc(
          studyPlanAssignmentRef,
          newAssignment
        );
        //Actualizar el ID
        await updateDoc(newStudyPlanRef, { ID: newStudyPlanRef.id })
          .then(async () => {
            newID = newStudyPlanRef.id;
            // actualizar el estado de control UI
            newAssignment &&
              setAssignment({ ...assignment, assignment: newAssignment });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    return newID;
  };

  const saveSPassignment = async (newAssignment: StudyPlanAssignment) => {
    //Referencia a Assignments
    const studyPlanAssignmentRef = doc(
      firestore,
      `Instances/${AppSettings.Name}/StudyPlansAssignments/${newAssignment.ID}`
    );

    setDoc(studyPlanAssignmentRef, newAssignment)
      .then(() => {
        // actualizar el estado de control UI
        newAssignment &&
          setAssignment({ ...assignment, assignment: newAssignment });
      })
      .catch((e) => {
        setMsg("Error, no tienes permisos?");
        console.log(e);
      });
  };

  /**
   * Verifica que el usuario no tenga un assigment
   * @param assigmentQuery son los documentos de assigments
   * @param user para saber qué usuario se debe encontrar
   * @param course para obtener el id del curso y verificar que el usuario no esté inscrito
   * @returns true si el usuario NO tiene assigments y SÍ se puede agregar. false si el usuario tiene
   * assigments y NO se puede agregar
   */
  const addEnroll = async (
    assigmentQuery: QuerySnapshot<DocumentData>,
    user: User,
    course: Course
  ) => {
    let array: boolean[] = [];

    if (!assigmentQuery.empty) {
      const assigns = assigmentQuery.docs.map((d) => d.data() as AssignmentV2);
      //se verifican los assigments y si el usuario de uno coincide con el usuario mandado
      //además de el id de curso, entonces no se puede agregar
      assigns.forEach((m) => {
        if (
          m.User.includes(user.UID ?? "") &&
          m.Course.includes(course.ID ?? "")
        ) {
          array.push(false);
        }
      });
    }
    //si el array tiene un false, significa que tiene un assigment, así que no puede
    //inscribirse
    if (array.includes(false)) {
      return false;
    }
    return true;
  };

  /**
   * Función para obtener los módulos de un curso para enrolar al usuario
   * @param courseID recibe el ID de curso para buscarlo en la base de datos
   */
  const getModules = async (courseID: string) => {
    //*Leer los Módulos que tiene el curso
    const modulesQuery = await getDocs(
      collection(
        firestore,
        `Instances/${AppSettings.Name}/Courses/${courseID}/Modules`
      )
    );
    if (!modulesQuery.empty) {
      const mods = modulesQuery.docs.map((d) => d.data() as Module);
      return mods;
    }
  };

  // ENROLLAR AL USUARIO EN TODOS LOS CURSOS DEL PLAN
  const enrollAllCourses = async () => {
    // para actualizar el contexto
    let listaCursos: Courses = { ...state.courses };
    let listaAssignments: AssignmentV2[] = [...(state.assignments2 ?? [])];

    // obtener los cursos de los ids de los cursos del plan de estudio
    let selectedCourses: Course[] = [];
    selectedIDCourses.forEach((id) => {
      let temp = coursesCollection.findIndex((course) => course.ID === id);

      if (temp >= 0) {
        selectedCourses.push(coursesCollection[temp]);
      }
    });

    // recorrer cada curso seleccionado
    for (const curso of selectedCourses) {
      //Se obtienen los módulos y se asigna un progreso vacio
      await getModules(curso.ID!)
        .then(async (mod) => {
          const emptyProgress = mod?.map((m, i) => {
            const prog: ModuleProgress = {
              ModuleProgress: 0,
              Topics: m.Topics.map((t, j) => {
                const tProg: TopicProgress = {
                  Approved: false,
                  Started: false,
                };
                return tProg;
              }),
            };
            return prog;
          });

          //Referencia a Assignments
          const assignmentsRef = collection(
            firestore,
            `Instances/${AppSettings.Name}/Assignments`
          );

          await getDocs(assignmentsRef)
            .then(async (res) => {
              if (await addEnroll(res, state.user, curso)) {
                // crear un assignment para cada usuario y asignar el progreso vació del curso
                const assignmentL: AssignmentV2 = {
                  Course: curso!.ID!,
                  User: state.user.UID!,
                  UserMail: state.user.Email!,
                  Progress: emptyProgress ?? [],
                  Data: {},
                };
                await addDoc(assignmentsRef, assignmentL)
                  .then(async () => {
                    listaAssignments.push(assignmentL);
                    listaCursos[curso!.ID!] = curso;
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return { courses: listaCursos, assignments: listaAssignments };
  };

  if (loading) {
    return <BackdropComponent />;
  } else {
    switch (AppSettings.Name) {
      default:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <StudyPlansPageHub.Default
              coursesCollection={coursesCollection}
              studyPlan={studyPlan}
              setStudyPlan={update}
              msg={msg}
              hasChanges={hasChanges}
              save={saveStudyPlan}
              deleteStudyPlan={deleteStudyPlan}
              setSelectedIDCourses={setSelectedIDCourses}
              selectedCourses={selectedCourses}
              selectedIDCourses={selectedIDCourses}
              showAlert={alertPublish}
              publishPlan={publishPlan}
              alertMessage={alertMessage}
              archivePlan={archive}
              seguirPlan={seguirPlan}
              assignmentUI={assignment}
              enrolling={enrolling}
            />
          </Suspense>
        );
    }
  }
};
export default withAuth(StudyPlansPage);
