import { Button, Fade, Grid, TextField, Typography } from "@mui/material";
import { Reta } from "../../../../../../../interfaces/reta";

interface Props {
    quiz: Reta;
    nombreEstudiante: string;
    setNombreEstudiante: (e: string) => void;
    handleInscribir: () => void;
    enable: boolean;
}

const SignIn: React.FC<Props> = ({ quiz, nombreEstudiante, handleInscribir, setNombreEstudiante, enable }) => {
    return (
        <Fade in={true} timeout={400}>
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6" fontWeight="bold">
                        Bienvenid@ a la reta de "{quiz.topic}"
                    </Typography>
                    <Typography >
                        Para comenzar por favor ingresa tu
                        nombre mientras esperas a que comience la sesión:
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Nombre"
                        sx={{ marginTop: 2 }}
                        value={nombreEstudiante}
                        onChange={(e) => { setNombreEstudiante(e.target.value) }}
                    />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        onClick={handleInscribir}
                        sx={{ textTransform: 'none' }}
                        disabled={!enable}
                    >
                        Jugar
                    </Button>
                </Grid>
            </Grid>
        </Fade>
    )
}
export default SignIn;