import { User as FBUser } from "firebase/auth";
import { State } from "../../../interfaces/context";
import { getData } from "./services";


export const GetDHLUser = async (auth: FBUser, state: State) => {
    const uid = auth.uid;
    // get data for current instance - user
    let data = state.user.Name
        ? state
        : await getData('DHL', uid);

    return { user: data.user, state: data };
}