import { useAppContext } from "../../auth/appContext";
import { UserContext } from "../../auth/userContext";
import PasswordModalView from "./view";
import { useEffect, useContext, useState } from 'react';
import { Timestamp } from "firebase/firestore";
import { fbFunctions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { useShowMessage } from "../../providers/MessageProvider/MessageProvider";
import { regExpConsecutiveChars, regExpLowerHighCase, regExpMinCharLength, regExpMinNumbers, specialCharRegExp } from "../../Config/constants";

const PasswordModal: React.FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [paws, setPaws] = useState({ paw: '', paw2: '' });
    const [visible, setVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [fecha, setFecha] = useState<Date>();
    const { AppSettings } = useAppContext();
    const { user } = useContext(UserContext);
    const { showMessage } = useShowMessage();

    /**
     * Verificar que la última fecha de cambio del usuario no haya superado
     * los días para que caduque una contraseña (esta configuración esta en la configuración
     * dentro del documento de la instancia)
     * De haber superado los días, se abre el dialogo para cambiar de contraseña
     */
    useEffect(() => {
        if (user.logged) {
            const timeFunction = httpsCallable(fbFunctions, 'Custom-Default-GetTime');
            const expirationDays = AppSettings.Config.PasswordExpiration ?? 365;
            timeFunction().then((res) => {
                if (user.LastPasswordChange) {
                    const fechaParaCambiar = Timestamp
                        .fromMillis(user.LastPasswordChange.toMillis() + (expirationDays * 24 * 60 * 60 * 1000)).toDate();
                    if (fechaParaCambiar < new Date(parseInt(res.data as any))) {
                        setShowModal(true);
                    }
                }
            })
        }
    }, [user, AppSettings.Config]);

    useEffect(() => {
        if (verifyPaw() && paws.paw === paws.paw2)
            setCanSave(true);
        else
            setCanSave(false);
    }, [paws]);

    /**
     * Verificar que la contraseña cumple los requisitos mínimos
     * @returns booleano si cumple o no cumple los requisitos
     */
    const verifyPaw = () => {
        return paws.paw.trim() !== '' &&
            paws.paw2.trim() !== '' &&
            regExpConsecutiveChars.test(paws.paw) &&
            regExpLowerHighCase.test(paws.paw) &&
            regExpMinCharLength.test(paws.paw) &&
            regExpMinNumbers.test(paws.paw) &&
            specialCharRegExp.test(paws.paw) &&
            ConsecutiveChars(paws.paw) &&
            paws.paw.length > 7
    }

    /**
    * Valida que la contraseña no repita y que no contenga palabras prohibidas por seguridad
    * @param paw contraseña de usuario
    * @returns boolean de contraseña segura
    */
    const ConsecutiveChars = (paw: string) => {
        const s = paw as any;
        for (let i in s)
            if (+s[+i + 1] === +s[i] + 1 && +s[+i + 2] === +s[i] + 2)
                return false;
        for (let i in s)
            if (String.fromCharCode(s.charCodeAt(i) + 1) === s[+i + 1] && String.fromCharCode(s.charCodeAt(i) + 2) === s[+i + 2])
                return false;
        return true;
    }

    /**
     * Cerrar el dialogo unicamente si se ha guardado una contraseña correcta
     */
    const handleClose = () => {
        setShowModal(false);
    }

    /**
     * Manejador de guardado de contraseñas
     */
    const handleSave = async () => {
        setLoading(true);
        const cloudChangePaw = httpsCallable(fbFunctions, 'Custom-Default-ResetPass');
        const res = await cloudChangePaw({ Email: user.Email, Password: paws.paw, LoginOptions: AppSettings.Config.LoginOptions }) as any;
        //Si no ocurrio ningun error la contraseña se cambio exitosamente y cierra dialogo
        if (res.data.error === false) {
            showMessage({
                text: 'La contraseña ha sido cambiada exitosamente',
                severity: 'success',
            })
            handleClose();
            //La contraseña ya existe dentro del registro
        } else if (res.data.error === 'Password Exists') {
            showMessage({
                text: 'La contraseña ingresada ya ha sido usada anteriormente, por favor escriba una diferente a las 5 anteriores',
                severity: 'warning',
            });
            //No debería ocurrir
        } else {
            showMessage({
                text: 'Ocurrió un error al cambiar la contraseña, por favor intente mas tarde o contacte al administador',
                severity: 'error',
            })
        }
        setLoading(false);
    }

    return <PasswordModalView
        handleClose={handleClose}
        open={showModal}
        paws={paws} setPaws={setPaws}
        handleSave={handleSave}
        visible={visible}
        setVisible={setVisible}
        canSave={canSave}
        loading={loading}
        ConsecutiveChars={ConsecutiveChars}
    />

}
export default PasswordModal;