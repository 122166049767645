import withAuth from "../../../../auth/withAuth";
import ActivitiesView from "./view";
import { useDataContext } from "../../../../auth/dataContext";
import { getDownloadUrl } from "../../../../helpers/index";
import { ActivityMaterial } from "../../../../interfaces";
import { useEffect, useState } from "react";

const Activities: React.FC = () => {
  const { state } = useDataContext();

  const [loading, setLoading] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState<string>("");
  const [selectedActivity, setSelectedActivity] = useState<
    ActivityMaterial | undefined
  >(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (selectedActivity) {
      setLoading(true);
      getDownloadUrl(selectedActivity.URL).then((url) => {
        setSelectedUrl(url);
        setLoading(false);
      });
    }
  }, [selectedActivity]);

  const assignment = state.assignments2![0];
  if (assignment) {
    const currentCourseId = assignment.Course;

    let lastCompletedModuleIndex = -1;
    let lastCompletedTopicIndex = -1;

    assignment!.Progress.forEach((moduleProgress, index) => {
      if (moduleProgress.ModuleProgress >= 1) {
        lastCompletedModuleIndex = index;
      } else if (lastCompletedTopicIndex === -1) {
        lastCompletedTopicIndex = moduleProgress.Topics.findIndex(
          (topicProgress) => !topicProgress.Approved
        );
      }
    });

    //console.log(lastCompletedModuleIndex, lastCompletedTopicIndex);

    const activitiesMaterial: ActivityMaterial[] = [];
    state.modules[currentCourseId].forEach((module, index) => {
      if (index <= lastCompletedModuleIndex + 1) {
        module.Topics.forEach((topic, i) => {
          if (
            index <= lastCompletedModuleIndex ||
            i < lastCompletedTopicIndex
          ) {
            topic.Material.forEach((material) => {
              if (material.Type === "pdfa") {
                activitiesMaterial.push({
                  Name: material.Name,
                  URL: material.URL!,
                  ModuleName: module.Name,
                  TopicName: topic.Name,
                  ID: material.Name + "-" + index + "-" + i,
                });
              }
            });
          }
        });
      }
    });

    return (
      <ActivitiesView
        activities={activitiesMaterial}
        selectedActivity={selectedActivity}
        setSelectedActivity={setSelectedActivity}
        selectedUrl={selectedUrl}
        loading={loading}
      />
    );
  } else {
    return (
      <h2 className="fadeInRigth">
        Debes estar inscrito al curso activo para poder ver esta sección.
      </h2>
    );
  }
};

export default withAuth(Activities);
