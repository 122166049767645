import { useEffect, Suspense } from 'react'
import { useAppContext } from '../../auth/appContext';
import * as CreateUsersExcelComponent from './Instances'

interface Props {
    open: boolean;
    setOpen: (e: boolean) => void;
}

const CreateUsersExcel: React.FC<Props> = ({ open, setOpen }) => {
    const { AppSettings } = useAppContext();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    switch (AppSettings.Name) {
        default:
            return (
                <Suspense fallback={<div>Loading...</div>}>
                    <CreateUsersExcelComponent.Default open={open} setOpen={setOpen} />
                </Suspense>
            );
    }
};

export default CreateUsersExcel;