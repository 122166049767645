import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
    createStyles({
        '@keyframes flicker': {
            from: {
                opacity: 1,
            },
            to: {
                opacity: 0.3,
            },
        },
        '@keyframes selected': {
            to: {
                border: '2px solid black',
                borderRadius: '2%'
            }
        },
        '@keyframes run': {
            '0%': {
                right: '-300px',
                opacity: 0,
            },
            '25%': {
                right: '-225px',
                opacity: 0.1,
            },
            '50%': {
                right: '-150px',
                opacity: 0.3,
                // transform: 'rotate(0deg)'
            },
            '75%': {
                right: '-75px',
                // transform: 'rotate(-65deg)'
            },
            '100%': {
                right: '0px',
                // transform: 'rotate(0deg)'
            }
        },
        selected: {
            // border: "2px solid black",
            animationName: '$selected',
            animationDuration: '200ms',
            animationDirection: 'normal',
            animationTimingFunction: 'linear',
            animationFillMode: 'forwards',
        },
        flicker: {
            animationName: '$flicker',
            animationDuration: '600ms',
            animationIterationCount: 'infinite',
            animationDirection: 'alternate',
            animationTimingFunction: 'ease-in-out',
        },
        animacion: {
            position: "relative",
            animationName: '$run',
            animationDuration: '1000ms',
            animationTimingFunction: 'linear',
            animationDirection: 'normal',
            animationTimeline: 'auto',
        }
    })
);
export default useStyles;