import React, { useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Paper, Select, Slide, TextField, Typography } from "@mui/material"
import { httpsCallable } from "firebase/functions";
import { fbFunctions, firestore } from "../../firebase";
import { useAppContext } from "../../auth/appContext";
import { doc, updateDoc } from "firebase/firestore";
import { ArrowBack } from "@mui/icons-material";
import { useShowMessage } from "../../providers/MessageProvider/MessageProvider";
import { CloudRes } from "../../interfaces/validation";
import { userRoles } from "../../Config/constants";
import { TransitionProps } from "@mui/material/transitions";
import { PasswordRules } from "@fridaplatform-stk/react-password-rules";
import _ from "lodash";
import useStyles from "../../pages/EditProfiles/Instances/Default/style";

interface Props {
    user: any;
    setUser: (e: any) => void;
}

const Transition = React.forwardRef(
    function Transition(props: TransitionProps & { children: React.ReactElement<any, any>; }, ref: React.Ref<unknown>,
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

const EditUserComponent: React.FC<Props> = ({ user, setUser }) => {
    const [paws, setPaws] = useState({ paw: '', paw2: '' });
    const [securePaw, setSecurePaw] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [provider, setProvider] = useState<string>('password2');
    const [openDisable, setOpenDisable] = useState<boolean>(false);
    const [openChangePaw, setOpenChangePaw] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const { AppSettings } = useAppContext();
    const { showMessage } = useShowMessage();
    const classes = useStyles();

    useEffect(() => {
        if (user?.UID) {
            getStatus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.UID]);

    useEffect(() => {
        if (user.UID) {
            if (user.Name.trim() === '' || user.Access.length >= 1)
                setCanSave(true)
            else
                setCanSave(false)
        } else {
            if ((user.Name.trim() === '' || user.Email.trim() === '' || user.Access.length >= 1)) {
                setCanSave(true);
            } else {
                setCanSave(false);
            }
        }
    }, [user, paws])

    /**
     * Cambiar contraseña con cloud function
     */
    const handleChangePass = async () => {
        setLoading(true);
        const changePass = httpsCallable(fbFunctions, 'Custom-Default-ResetPass');
        const res = await changePass({
            Email: user.Email,
            Password: paws.paw,
            LoginOptions: AppSettings.Config.LoginOptions,
        }) as any;
        if (res.data.error === false) {
            showMessage({
                text: 'Contraseña cambiada exitosamente',
                severity: 'success',
            });
            setOpenChangePaw(false);
            setPaws({
                paw: '',
                paw2: '',
            })
        } else if (res.data.error === 'Password Exists') {
            showMessage({
                text: `La contraseña ingresada ha sido usada anteriormente, por favor ingrese una nueva.`,
                severity: 'error',
            });
        } else {
            console.log(res.data)
            showMessage({
                text: 'Ocurrio un error al cambiar la contraseña, por favor intente mas tarde o contacte al administrador',
                severity: 'error',
            });
        }

        setLoading(false);
    }

    /**
     * Obtener estatus de usuario en autenticathion con cloud function
     */
    const getStatus = async () => {
        const getUserStatus = httpsCallable(fbFunctions, 'Custom-Default-GetUserStatus');
        const res = await getUserStatus({ Email: user?.Email }) as CloudRes;
        if (!res.data.error) {
            setDisabled(res.data.status!);
        } else {
            console.log(res.data.error)
        }
    }

    /**
     * Manejador de guardado o edición de usuarios
     */
    const handleSave = async () => {
        setLoading(true);
        if (user?.UID) {
            try {
                const userRef = doc(firestore, `Instances/${AppSettings.Name}/Users/${user.UID}`);
                await updateDoc(userRef, {
                    Name: user.Name,
                    Access: user.Access,
                    SVC: user.SVC,
                    Region: user.Region,
                });
                showMessage({
                    text: 'Usuario actualizado correctamente',
                    severity: 'success',
                });
                setUser(undefined);
            } catch (e) {
                console.log(e)
            }
        } else {
            const data = {
                Email: user?.Email,
                Name: user?.Name,
                Provider: user.Provider,
                Access: user.Access,
                Password: paws.paw,
                SVC: user.SVC,
                Region: user.Region,
                LoginOptions: AppSettings.Config.LoginOptions,
            }
            // console.log(data)
            const createUserFunction = httpsCallable(fbFunctions, 'Custom-Default-CreateUser');
            const res = await createUserFunction(data) as CloudRes;
            if (!res.data.error) {
                showMessage({
                    text: 'Usuario creado exitosamente',
                    severity: 'success',
                });
                setUser(undefined);
            } else {
                showMessage({
                    text: 'Ocurrio un error al crear el usuario, por favor intente mas tarde o contacte al administrador',
                    severity: 'error',
                });
                // console.log(res.data)
            }
        }
        setLoading(false);
    }

    /**
     * Cambiar estatus de usuario en autenticathion (habilitar / deshabilitar)
     */
    const handleChangeUserStatus = async () => {
        setLoading(true);
        const disableUserFunction = httpsCallable(fbFunctions, 'Custom-Default-DisableUser');
        const res = await disableUserFunction({ Email: user?.Email, Disabled: !disabled }) as CloudRes;
        if (!res.data.error) {
            showMessage({
                text: `El usuario ha sido ${!disabled ? 'deshabilitado' : 'habilitado'} exitosamente`,
                severity: 'success',
            })
            setOpenDisable(false);
            getStatus();
        } else {
            console.log(res.data.msg)
        }
        setLoading(false);
    }

    /**
     * Convertir a tipo array string los roles seleccionados
     */
    const selectedCategories = useMemo(() => {
        let value = "";
        userRoles.forEach((role) => {
            if (user.Access.some((strRole: string) => strRole === role.value)) {
                if (value) {
                    value += ", " + role.name;
                } else {
                    value = role.name;
                }
            }
        });
        return value;
    }, [user.Access]);

    return (
        <Grid item xs={12} md={8} container component={Paper} sx={{ p: 1.5 }}
            className={classes.fade}
        >
            <Dialog
                fullWidth
                open={openDisable || openChangePaw}
                TransitionComponent={Transition}
                onClose={(e, reason) => {
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        setOpenChangePaw(false); setOpenDisable(false);
                    }
                }}
            >
                <DialogTitle>
                    <Typography>
                        {openDisable ? `¿Esta seguro que desea ${disabled ? 'habilitar' : 'deshabilitar'} el usuario?` :
                            'Por favor escriba la nueva contraseña para continuar'}
                    </Typography>
                    {!openDisable &&
                        <Typography variant="body2" fontWeight='bold'>
                            Nota: No debe ser la misma a alguna de las 5 contraseñas anteriores.
                        </Typography>
                    }
                </DialogTitle>
                {openChangePaw &&
                    <DialogContent>
                        <Grid container sx={{ mt: 1 }} spacing={1}>
                            <PasswordRules
                                Passwords={paws}
                                setPasswords={setPaws}
                                setSecurePaw={setSecurePaw}
                            />
                        </Grid>
                    </DialogContent>
                }
                <DialogActions>
                    <Grid container justifyContent='end' spacing={1}>
                        <Grid item>
                            <Button
                                color='secondary' variant='contained'
                                onClick={() => {
                                    setOpenDisable(false);
                                    setOpenChangePaw(false);
                                    setPaws({
                                        paw: '',
                                        paw2: '',
                                    });
                                    setSecurePaw(false);
                                }}
                                disabled={loading}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                onClick={openDisable ? handleChangeUserStatus : handleChangePass}
                                disabled={openDisable ? loading : securePaw}
                            >
                                {openDisable ? disabled ? 'Habilitar' : 'Deshabilitar' : 'Cambiar contraseña'}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            <Grid item xs={12} container alignItems='center'>
                <IconButton
                    onClick={() => {
                        setUser(undefined)
                    }}
                >
                    <ArrowBack />
                </IconButton>
                <Typography variant='h5'>
                    {user.UID ? 'Editar' : 'Crear nuevo'} usuario
                </Typography>
            </Grid>
            <Grid item container xs={12} spacing={2} sx={{ mt: 0 }}>
                {user.UID &&
                    <Grid item xs={12} justifyContent='center' container>
                        <Typography variant='h6' fontWeight='bold'>
                            Este usuario se encuentra
                        </Typography>
                        <Chip
                            sx={{ width: '100%', fontWeight: 'bold' }}
                            label={disabled ? 'Deshabilitado' : 'Habilitado'}
                            color={disabled ? 'error' : 'success'}
                        />
                    </Grid>}
                <Grid item xs={12}>
                    <TextField
                        label={'Nombre'}
                        value={user.Name}
                        onChange={(e) => { setUser({ ...user, Name: e.target.value }) }}
                        fullWidth
                        size='small'
                    />
                </Grid>
                {!user.UID &&
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel>Proveedor de acceso</InputLabel>
                            <Select
                                value={provider}
                                label="Proveedor de acceso"
                                onChange={(e) => {
                                    setProvider(e.target.value);
                                    if (e.target.value === 'password') {
                                        setUser({ ...user, Email: user.Email.replace(/@/g, "") })
                                    }
                                }}
                                size="small"
                            >
                                <MenuItem value={'password2'}>Correo electrónico</MenuItem>
                                {_.filter(AppSettings.Config.LoginOptions, (loginMethod) => _.has(loginMethod, 'fake_email')).length > 0 &&
                                    <MenuItem value={'password'}>ID de usuario</MenuItem>
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                }

                <Grid item xs={12}>
                    <TextField
                        label={provider === 'password' ? 'ID de usuario' : 'Email'}
                        value={user.Email}
                        onChange={(e) => {
                            setUser({ ...user, Email: e.target.value })
                        }}
                        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (provider === 'password') {
                                if (e.key === '@') {
                                    e.preventDefault();
                                    return
                                }
                            }
                        }}
                        fullWidth
                        size='small'
                        disabled={user.UID ? true : false}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Roles de usuario</InputLabel>
                        <Select
                            multiple
                            value={user.Access}
                            onChange={(e) => {
                                setUser({
                                    ...user,
                                    Access: e.target.value
                                });
                            }}
                            input={<OutlinedInput label="Roles de usuario" />}
                            renderValue={() => selectedCategories}
                        >
                            {userRoles.map((role) => (
                                <MenuItem key={role.name} value={role.value}>
                                    <Checkbox
                                        checked={user.Access.some((strRole: string) => strRole === role.value)}
                                    />
                                    <ListItemText primary={role.name} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='SVC'
                        // placeholder='SVC'
                        size='small'
                        fullWidth
                        value={user.SVC ?? ''}
                        onChange={(e) => {
                            setUser({ ...user, SVC: e.target.value })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label='Region'
                        // placeholder='SVC'
                        size='small'
                        fullWidth
                        value={user.Region ?? ''}
                        onChange={(e) => {
                            setUser({ ...user, Region: e.target.value.toUpperCase() })
                        }}
                    />
                </Grid>

                {!user.UID &&
                    <Grid item xs={12}>
                        <PasswordRules
                            Passwords={paws}
                            setPasswords={setPaws}
                            setSecurePaw={setSecurePaw}
                        />
                    </Grid>
                }
                <Grid item xs={12} container justifyContent='flex-end' spacing={1}>
                    {user.Provider !== 'microsoft.com' && user.UID &&
                        <Grid item>
                            <Button
                                color='primary' variant='outlined'
                                onClick={() => {
                                    setOpenChangePaw(true)
                                }}
                                disabled={loading}
                            >
                                Cambiar contraseña
                            </Button>
                        </Grid>
                    }
                    {user.UID &&
                        <Grid item>
                            <Button
                                color='secondary' variant='outlined'
                                onClick={() => {
                                    setOpenDisable(true);
                                }}
                                disabled={loading}
                            >
                                {disabled ? 'Habilitar' : 'Deshabilitar'} usuario
                            </Button>
                        </Grid>
                    }
                    <Grid item>
                        <Button
                            variant='contained'
                            disabled={!canSave || loading}
                            onClick={handleSave}
                        >
                            {loading ? 'Cargando...' : 'Guardar'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export default EditUserComponent;