import {makeStyles, createStyles} from "@mui/styles";
import {Theme} from "@mui/system";
import {ClientSettings} from "../../../interfaces";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			width: "99%",
			boxShadow:
				"rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset",
			borderLeftStyle: "solid",
			paddingBottom: "10px",
		},
		cardBorder: {
			borderLeftColor: ({
				AppSettings,
				borderLeftColor,
			}: {
				AppSettings: ClientSettings | undefined;
				borderLeftColor: string | undefined;
			}) => {
				return borderLeftColor === undefined || borderLeftColor === ""
					? AppSettings?.DesignSettings.Palette.primary.main
					: borderLeftColor;
			},
		},
		contactInfo: {
			display: "flex",
			alignItems: "center",
			flexWrap: "wrap",
			margin: 2,
		},
		contactInfoIcon: {
			marginRight: 6,
			padding: 2,
		},
		actualCourseContainer: {
			alignItems: "start",
			display: "flex",
			justifyContent: "space-between",
			padding: ".6rem 1rem",
		},
	})
);

export default useStyles;
