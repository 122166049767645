import { Close, Search } from "@mui/icons-material"
import { Grid, IconButton, InputAdornment, TextField, styled } from "@mui/material"

interface Props {
    search: string;
    setSearch: (text: string) => void;
}
export const CustomCertsTableToolbar = ({ search, setSearch }: Props) => {

    return (
        <Grid container alignItems='center' justifyContent='flex-end'
            sx={{
                p: 1,
                backgroundColor: '#000'
            }}
        >
            <Grid item xs={6}>
                <TextField
                    size='small'
                    label={'Buscar Service Center'}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment
                                position="start"
                            >
                                <Search
                                    sx={{
                                        color: '#fff !important'
                                    }}
                                    fontSize='small'
                                />
                            </InputAdornment>
                        ),
                        endAdornment: search !== '' && (
                            <InputAdornment
                                position="end"
                            >
                                <IconButton
                                    edge='end'
                                    onClick={() => setSearch('')}
                                >
                                    <Close
                                        sx={{
                                            color: '#fff !important'
                                        }}
                                        fontSize='small'
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    variant="outlined"
                    sx={{
                        borderColor: '#fff !important',
                        color: '#ffffff !important',
                        '& .MuiInputBase-root': {
                            color: '#fff',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#fff !important',
                            borderWidth: '3px solid !important'
                        },
                        '& label': {
                            color: '#fff !important',
                        },
                        '& label.Mui-focused': {
                            color: '#ffffff !important',
                        },
                        '& .MuiInput-underline:after': {
                            borderBottomColor: '#ffffff !important',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: 'gray',
                            },
                            '&:hover fieldset': {
                                borderColor: '#ffffff !important',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#ffffff !important',
                            },
                        },
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    )
}