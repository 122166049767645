import {Card, CardContent, Typography} from "@mui/material";
import useStyles from "./style";
import {Report} from "../../../interfaces/index";
import {
	InfoOutlined,
	BarChartRounded,
	PieChartRounded,
	BubbleChartRounded,
	TableChartRounded,
	DonutLargeRounded,
	PictureAsPdf
} from "@mui/icons-material";

interface Props {
	report: Report;
}

const icons: {[key: string]: any} = {
	bar: <BarChartRounded color='secondary' />,
	pie: <PieChartRounded color='secondary' />,
	bubble: <BubbleChartRounded color='secondary' />,
	table: <TableChartRounded color='secondary' />,
	donut: <DonutLargeRounded color='secondary' />,
	pdf: <PictureAsPdf color='secondary' />
};

const ReportCard: React.FC<Props> = ({report}) => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent>
				<Typography
					variant='h6'
					display='flex'
					justifyContent='space-between'
					gutterBottom
					alignItems='center'>
					{report.Name}
					{icons[report.ChartType!] ?? <InfoOutlined color='secondary' />}
				</Typography>

				<Typography className={classes.description} variant='body2'>
					{report.Description}
				</Typography>
			</CardContent>
		</Card>
	);
};

export default ReportCard;
