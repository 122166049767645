import React from "react";
import { Context, Action, ProviderProps, State } from "../interfaces/context";

// initial state
const DataState: State = {
  assignments2: [],
  courses: {},
  modules: {},
  user: {},
  studyPlans: {},
  studyPlansAssignments: [],
};

// context
const DataContext = React.createContext<Context | undefined>(undefined);

// reducer: is used to be able to change the data
function reducer(state: State, action: Action) {
  switch (action.type) {
    case "set": {
      return { ...state, ...action.values };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// provider
export function DataProvider({ children }: ProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, DataState);
  const value = { state, dispatch };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
}

// use the context
export const useDataContext = (): Context => {
  const context = React.useContext(DataContext);
  if (context === undefined) {
    throw new Error("Data must be used within a DataProvider");
  }
  return context;
};
