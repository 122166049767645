import { Route, Routes } from "react-router-dom";
import Login from "../../../../pages/Login";

/**
 * Devuelve las rutas que se pueden ver en el sitio
 * para cualquier instancia (cliente) sin personalización
 * Sería solamente * que redirige a la pantalla de Login
 * @returns Rutas públicas
 */
const DefaultPublicRouter = () => {
return <Routes>
    <Route path="*" element={<Login />} />
</Routes>
}

export default DefaultPublicRouter;