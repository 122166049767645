import { GridSelectionModel } from "@mui/x-data-grid";
import {
  addDoc,
  collection,
  DocumentData,
  getDocs,
  QuerySnapshot,
  updateDoc,
} from "firebase/firestore";
import React, { Suspense, useEffect, useState } from "react";
import { useAppContext } from "../../auth/appContext";
import withAuth from "../../auth/withAuth";
import { firestore } from "../../firebase";
import { User } from "../../interfaces";
import {
  AssignmentV2,
  ModuleProgress,
  TopicProgress,
} from "../../interfaces/assignment";
import { Course, Module } from "../../interfaces/course";
import { StudyPlan, StudyPlanAssignment } from "../../interfaces/studyplans";
import * as EnrollerPageHub from "./Instances";

const EnrollerPage: React.FC = () => {
  const { AppSettings } = useAppContext();
  const [usersCollection, setUsersCollection] = useState<User[]>([]); //lista de usuarios (todos)
  const [coursesCollection, setCoursesCollection] = useState<Course[]>([]); //lista de cursos (todos)
  const [userResult, setUserResult] = useState<any[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [selectedIDCourses, setSelectedIDCourses] =
    useState<GridSelectionModel>([]); //cursos seleccionados en el dataGrid
  const [selectedUIDS, setSelectedUIDS] = useState<GridSelectionModel>([]); //uids de usuarios seleccionadas  en el datagrid
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]); //objeto usuarios seleccionados en el grid
  const [showCourses, setShowCourses] = useState<boolean>(false); // ¿UI muestra la tabla de cursos?
  const [isEnrrolling, setEnrolling] = useState<boolean>(false);
  const [showPlanes, setShowPlanes] = useState<boolean>(false);
  const [planesCollection, setPlanesCollection] = useState<StudyPlan[]>([]);
  const [selectedIDPlans, setSelectedIDPlans] = useState<GridSelectionModel>(
    []
  );

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // obtener los cursos solo cuando se muestra la pantalla de cursos
    showCourses && coursesCollection.length === 0 && getCourses();
  }, [showCourses]);

  useEffect(() => {
    // obtener los cursos solo cuando se muestra la pantalla de cursos
    showPlanes && planesCollection.length === 0 && getStudyPlans();
  }, [showPlanes]);

  /**
   * Se obtienen los usuarios de la base de datos
   */
  const getUsers = async () => {
    const usersRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/Users`
    );
    const usersQuery = await getDocs(usersRef);
    if (!usersQuery.empty) {
      const usersCollection = usersQuery.docs.map((u) => u.data() as User);
      setUsersCollection(usersCollection);
    }
  };

  /**
   * Se obtienen los cursos de la base de datos y se filtran por los que están
   * únicamente activos
   */
  const getCourses = async () => {
    const courseRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/Courses`
    );
    const courseQuery = await getDocs(courseRef);
    if (!courseQuery.empty) {
      const courses = courseQuery.docs.map((u) => u.data() as Course);
      setCoursesCollection(courses.filter((c) => !c.isDraft && !c.isArchived));
    }
  };

  const getStudyPlans = async () => {
    const studyPlansRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/StudyPlans`
    );
    const spQuery = await getDocs(studyPlansRef);
    if (!spQuery.empty) {
      const studyPlans = spQuery.docs.map((u) => u.data() as StudyPlan);
      setPlanesCollection(
        studyPlans.filter((c) => !c.isDraft && !c.isArchived)
      );
    }
  };

  /**
   * Función para obtener los módulos de un curso para enrolar al usuario
   * @param courseID recibe el ID de curso para buscarlo en la base de datos
   */
  const getModules = async (courseID: string) => {
    //*Leer los Módulos que tiene el curso
    const modulesQuery = await getDocs(
      collection(
        firestore,
        `Instances/${AppSettings.Name}/Courses/${courseID}/Modules`
      )
    );
    if (!modulesQuery.empty) {
      const mods = modulesQuery.docs.map((d) => d.data() as Module);
      return mods;
    }
  };

  //   crear un Assignment (curso) por cada usuario - curso
  const enrolling = async () => {
    setEnrolling(true);
    // obtener los cursos de los ids de los cursos seleccionados en grid
    let selectedCourses: Course[] = [];
    selectedIDCourses.forEach((id) => {
      let temp = coursesCollection.findIndex((course) => course.ID === id);

      if (temp >= 0) {
        selectedCourses.push(coursesCollection[temp]);
      }
    });

    let lisRes: any[] = []; //lista de resultados de la inscripción
    // recorrer cada curso seleccionado
    for (const curso of selectedCourses) {
      //Se obtienen los módulos y se asigna un progreso vacio
      await getModules(curso.ID!)
        .then(async (mod) => {
          const emptyProgress = mod?.map((m, i) => {
            const prog: ModuleProgress = {
              ModuleProgress: 0,
              Topics: m.Topics.map((t, j) => {
                const tProg: TopicProgress = {
                  Approved: false,
                  Started: false,
                };
                return tProg;
              }),
            };
            return prog;
          });

          //se recorre la lista de usuarios para crear un assignment para cada uno de ellos
          for (const us of selectedUsers) {
            let tempResult = {
              userID: us.UID ?? "",
              name: us.Name ?? "",
              correo: us.Email,
              assignmentID: curso.ID ?? "",
              assginmentName: curso.Name,
              enrroll: true,
              mensaje: "Estudiante inscrito con éxito",
            };
            //Referencia a Assignments
            const assignmentsRef = collection(
              firestore,
              `Instances/${AppSettings.Name}/Assignments`
            );

            await getDocs(assignmentsRef)
              .then(async (res) => {
                if (await addEnroll(res, us, curso)) {
                  // crear un assignment para cada usuario y asignar el progreso vació del curso
                  const assignment: AssignmentV2 = {
                    Course: curso!.ID!,
                    User: us.UID!,
                    UserMail: us.Email!,
                    Progress: emptyProgress ?? [],
                    Data: {},
                  };
                  await addDoc(assignmentsRef, assignment)
                    .then(async () => {
                      lisRes.push(tempResult);
                    })
                    .catch((err) => {
                      console.log(err);
                      lisRes.push({
                        ...tempResult,
                        enrroll: false,
                        mensaje: "Ocurrió un error al inscribir al estudiante",
                      });
                    });
                } else {
                  lisRes.push({
                    ...tempResult,
                    enrroll: false,
                    mensaje: "El estudiante ya estaba inscrito en el curso",
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
    //al final enviar la lista de los usuarios que no se pudieron agregar
    setUserResult(lisRes);
    setShowResults(true);
    setEnrolling(false);
  };

  // Crear un assignment (plan de estudio) por cada usuario
  const enrollSP = async () => {
    setEnrolling(true);
    let lisRes: any[] = []; //lista de resultados de la inscripción

    let selectedPlans: StudyPlan[] = [];
    selectedIDPlans.forEach((id) => {
      let temp = planesCollection.findIndex((plan) => plan.ID === id);

      if (temp >= 0) {
        selectedPlans.push(planesCollection[temp]);
      }
    });

    // recorrer cada plan de estudio seleccionado
    for (const plan of selectedPlans) {
      //se recorre la lista de usuarios para crear un assignment para cada uno de ellos
      for (const us of selectedUsers) {
        let tempResult = {
          userID: us.UID ?? "",
          name: us.Name ?? "",
          correo: us.Email,
          assignmentID: plan.ID!,
          assginmentName: plan.Name,
          enrroll: true,
          mensaje: "Plan de estudio sugerido con éxito",
        };
        //Referencia a Assignments
        const studyPlanAssignmentRef = collection(
          firestore,
          `Instances/${AppSettings.Name}/StudyPlansAssignments`
        );

        await getDocs(studyPlanAssignmentRef)
          .then(async (res) => {
            if (await addEnrollSP(res, us, plan.ID!)) {
              // crear un assignment para cada usuario
              const assignment: StudyPlanAssignment = {
                ID: "nuevoID",
                StudyPlan: plan.ID!,
                User: us.UID!,
                UserMail: us.Email!,
                Data: {},
                TakenByUser: false,
              };
              const newStudyPlanRef = await addDoc(
                studyPlanAssignmentRef,
                assignment
              );
              //Actualizar el ID
              await updateDoc(newStudyPlanRef, { ID: newStudyPlanRef.id })
                .then(async () => {
                  lisRes.push(tempResult);
                })
                .catch((err) => {
                  console.log(err);
                  lisRes.push({
                    ...tempResult,
                    enrroll: false,
                    mensaje: "Ocurrió un error al sugerir el plan de estudio",
                  });
                });
            } else {
              lisRes.push({
                ...tempResult,
                enrroll: false,
                mensaje: "El plan de estudio ya fue sugerido al estudiante",
              });
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
    //al final enviar la lista de los usuarios que no se pudieron agregar
    setUserResult(lisRes);
    setShowResults(true);
    setEnrolling(false);
  };

  /**
   * Verifica que el usuario no tenga un assigment
   * @param assigmentQuery son los documentos de assigments
   * @param user para saber qué usuario se debe encontrar
   * @param course para obtener el id del curso y verificar que el usuario no esté inscrito
   * @returns true si el usuario NO tiene assigments y SÍ se puede agregar. false si el usuario tiene
   * assigments y NO se puede agregar
   */
  const addEnroll = async (
    assigmentQuery: QuerySnapshot<DocumentData>,
    user: User,
    course: Course
  ) => {
    let array: boolean[] = [];

    if (!assigmentQuery.empty) {
      const assigns = assigmentQuery.docs.map((d) => d.data() as AssignmentV2);
      //se verifican los assigments y si el usuario de uno coincide con el usuario mandado
      //además de el id de curso, entonces no se puede agregar
      assigns.forEach((m) => {
        if (
          m.User.includes(user.UID ?? "") &&
          m.Course.includes(course.ID ?? "")
        ) {
          array.push(false);
        }
      });
    }
    //si el array tiene un false, significa que tiene un assigment, así que no puede
    //inscribirse
    if (array.includes(false)) {
      return false;
    }
    return true;
  };

  /**
   * Verifica que el usuario no tenga un StudyPlan assignment de cierto Study Plan
   * @param assigmentQuery documentos del assignment
   * @param user usuario en cuestion
   * @param planID id del plan a buscar
   * @returns true si no tiene asignments (se puede enrolar) false si tiene asignments (no se puede enrolar)
   */
  const addEnrollSP = async (
    assigmentQuery: QuerySnapshot<DocumentData>,
    user: User,
    planID: string
  ) => {
    let array: boolean[] = [];

    if (!assigmentQuery.empty) {
      const assigns = assigmentQuery.docs.map(
        (d) => d.data() as StudyPlanAssignment
      );
      //se verifican los assigments y si el usuario de uno coincide con el usuario mandado
      //además de el id de plan, entonces no se puede agregar
      assigns.forEach((m) => {
        if (m.User.includes(user.UID ?? "") && m.StudyPlan.includes(planID)) {
          array.push(false);
        }
      });
    }
    //si el array tiene un false, significa que tiene un assigment, así que no puede
    //inscribirse
    if (array.includes(false)) {
      return false;
    }
    return true;
  };

  //   limpiar los campos
  const reset = () => {
    setShowCourses(false);
    setShowResults(false);
    setSelectedIDCourses([]);
    setSelectedUIDS([]);
    setSelectedUsers([]);
    setUserResult([]);
    setEnrolling(false);
    setShowPlanes(false);
    setSelectedIDPlans([]);
  };

  switch (AppSettings.Name) {
    default:
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <EnrollerPageHub.Default
            usersCollection={usersCollection}
            coursesCollection={coursesCollection}
            enrolling={enrolling}
            userResult={userResult}
            showResults={showResults}
            selectedIDCourses={selectedIDCourses}
            setSelectedIDCourses={setSelectedIDCourses}
            selectedUIDS={selectedUIDS}
            setSelectedUIDS={setSelectedUIDS}
            setSelectedUsers={setSelectedUsers}
            showCourses={showCourses}
            setShowCourses={setShowCourses}
            isEnrrolling={isEnrrolling}
            reset={reset}
            showPlanes={showPlanes}
            setShowPlanes={setShowPlanes}
            studyPlans={planesCollection}
            selectedIDPlans={selectedIDPlans}
            setSelectedIDPlans={setSelectedIDPlans}
            enrollPlanes={enrollSP}
          />
        </Suspense>
      );
  }
};

export default withAuth(EnrollerPage);
