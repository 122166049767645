import { Button, Fade, Grid, Paper } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import EditUserComponent from "../../../../components/EditUser";
import CreateUsersExcel from "../../../../components/CreateUsersExcel";
import { useState } from 'react';

interface Props {
    cols: GridColDef[];
    rows: any[];
    user: any | undefined;
    setUser: (e: any) => void;
    stringifyFilters: any;
}

const UsersAdminView: React.FC<Props> = ({ cols, rows, user, setUser, stringifyFilters }) => {
    const [open, setOpen] = useState<boolean>(false);

    if (user)
        return <EditUserComponent setUser={setUser} user={user} />
    else
        return (
            <Grid container component={Paper}>
                <Grid container sx={{ p: 1 }}>
                    <Grid item xs={12}>
                        <CreateUsersExcel open={open} setOpen={setOpen} />
                        <Fade in={stringifyFilters.length !== 0}>
                            <Grid item xs={12}>
                                {stringifyFilters}
                            </Grid>
                        </Fade>
                        <DataGrid
                            columns={cols}
                            rows={rows}
                            sx={{
                                minHeight: '75vh'
                            }}
                            getRowId={(row) => row.UID}
                            disableSelectionOnClick
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{
                                position: 'fixed',
                                top: '90%',
                                right: '2%'
                            }}
                            onClick={() => {
                                setUser({
                                    Access: ['student'],
                                    Email: '',
                                    Name: '',
                                    Provider: 'password',
                                    Data: {}
                                })
                            }}
                        >
                            Crear usuario
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant='contained'
                            sx={{
                                position: 'fixed',
                                top: '90%',
                                left: '7%'
                            }}
                            onClick={() => {
                                setOpen(true);
                            }}
                        >
                            Cargar usuarios con excel
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
}
export default UsersAdminView;