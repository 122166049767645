import { Route, Routes } from "react-router-dom"
import Login from "../../../../pages/Login"
import GameQuizClient from "../../../../pages/Z_Custom/DHL/Reta/GameQuiz/Client"

/**
 * Devuelve las rutas que se pueden ver en DHL
 * SOLO cuando no se tiene sesión iniciada
 * @returns Rutas públicas de la instancia DHL
 */
const DHLPublicRouter = () => {
    return <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/play/:gameRoomID" element={<GameQuizClient />} />
    </Routes>
}

export default DHLPublicRouter