import { Suspense } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import ReportCard from "../../components/Reports/ReportCard";
import { Course } from "../../interfaces/course";
import { Report } from "../../interfaces";
import { Default, DHL } from "./Instances";
import { ArrowBack } from "@mui/icons-material";
import BackdropComponent from "../../components/Backdrop";
import { SelectChangeEvent } from "@mui/material/Select";
import Dashboard from "../../components/Dashboard";
import { orderBy } from "lodash";
import React from "react";
import { useDataContext } from "../../auth/dataContext";

interface Props {
  reports: Report[];
  selectedCourseIndex: number;
  courses: Course[];
  selectedReport: any;
  handleSelectedCourse: (event: SelectChangeEvent) => void;
  handleSelectedReport: (report: any) => void;
  instanceName: string;
  loading: boolean;
  courseID: string;
  resultReports: Report[];
}

const ReportsView: React.FC<Props> = ({
  reports,
  courses,
  selectedCourseIndex,
  selectedReport,
  handleSelectedCourse,
  handleSelectedReport,
  instanceName,
  loading,
  courseID,
  resultReports,
}) => {
  const { state } = useDataContext();
  const renderReport = () => {
    switch (instanceName) {
      case "DHL":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Grid item xs={12}>
              <DHL
                courses={courses}
                report={selectedReport}
                courseID={courseID}
                resultReports={resultReports}
              />
            </Grid>
          </Suspense>
        );
      default:
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <Grid item xs={12}>
              <Default report={selectedReport} />
            </Grid>
          </Suspense>
        );
    }
  };

  return (
    <Grid
      container
      className="fadeInRigth"
      columnSpacing={3}
      rowSpacing={1}
      sx={{
        padding: 1,
      }}
    >
      <Grid item xs={12} marginBottom={2}>
        <FormControl variant="standard" fullWidth>
          <InputLabel color="secondary">Curso</InputLabel>
          <Select
            value={
              selectedCourseIndex === -1 ? "" : String(selectedCourseIndex)
            }
            color="secondary"
            onChange={handleSelectedCourse}
            label="Curso"
          >
            {orderBy(courses, "Name", "asc").map((course, index) => (
              <MenuItem key={course.Name} value={index}>
                {course.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {selectedReport && (
        <Grid item xs={12}>
          <IconButton onClick={() => handleSelectedReport(null)}>
            <ArrowBack
              sx={{
                color: "#000",
              }}
            />
          </IconButton>
        </Grid>
      )}
      {loading && <BackdropComponent />}
      {!loading && reports.length === 0 && (
        <Grid item xs={12}>
          {selectedCourseIndex !== -1 ? (
            <Typography fontWeight="bold" textAlign="center">
              No hay reportes para este curso
            </Typography>
          ) : (
            <Dashboard />
          )}
        </Grid>
      )}
      {!selectedReport &&
        reports.map((report) => {
          if (report.Type === "results") {
            let reportH = { ...report };
            reportH.Name = "Historico de Certificaciones";
            reportH.Description = "Comparativo de certificaciones entre años ";
            reportH.Type = "results_comparative";

            return (
              <React.Fragment key={"historicCards"}>
                {Object.keys(report.Data).length > 0 && (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={report.Name}
                    onClick={() => handleSelectedReport(report)}
                  >
                    <ReportCard report={report} />
                  </Grid>
                )}
                {!state.user.Data?.isManager && (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={"historico"}
                    onClick={() => handleSelectedReport(reportH)}
                  >
                    <ReportCard report={reportH} key={"historico reportCard"} />
                  </Grid>
                )}
              </React.Fragment>
            );
          }

          if (Object.keys(report.Data).length === 0) {
            return <></>;
          }
          return (
            <Grid
              item
              lg={3}
              md={4}
              sm={6}
              xs={12}
              key={report.Name}
              onClick={() => handleSelectedReport(report)}
            >
              <ReportCard report={report} />
            </Grid>
          );
        })}
      {renderReport()}
    </Grid>
  );
};

export default ReportsView;
