import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { firestore } from "../../../firebase";
import {
  AssignmentV2,
} from "../../../interfaces/assignment";
import { Course, Module, StudyTip } from "../../../interfaces/course";
import { Courses, Modules } from "../../../interfaces/ui";
import { State } from "../../../interfaces/context";
import { ClientSettings, User } from "../../../interfaces";

/**
 * Recuperar la información necesaria para el contexto de Virtual University
 * @param instance Nombre de la instancia en la que estamos trabajando
 * @param userID ID del usuario de la sesión, el cual queremos recuperar de DB
 * @returns Información necesaria para el contexto
 */
export const getData = async (instance: string, userID: string): Promise<State> => {
  // 0. Get the instance (DHL)
  const dhl: ClientSettings = await getInstance('DHL');
  const cursoActivo = dhl.Data.activeCourse;
  console.log(cursoActivo)

  // 1. Get user data
  const user: User = await getUser(instance, userID);

  // 2. Get user assignments
  const assignments = await getAssignments(instance, user.UID!, cursoActivo);

  // 3. Get user courses by assignment
  let courses: Courses = {};
  const assignmentsArray = Object.values(assignments);
  for (const assignment of assignmentsArray) {
    const courseID = assignment.Course;
    const current = await getCourse(instance, courseID);
    if (current !== null) courses[courseID] = current as unknown as Course;
  }

  // 4. Get user modules by course
  let modules: Modules = {};
  const coursesArray = Object.keys(courses);
  for (const courseID of coursesArray) {
    let currentModules = await getModules(instance, courseID);
    modules[courseID] = currentModules as unknown as Module[];
  }

  // 5. Get StudyTips
  const tips: StudyTip[] = await getStudyTips(instance);

  return { user, assignments2: assignments, courses, modules, studyTips: tips };
};

/**
 * Recuperar el objeto de la instancia
 * @param instanceName Nombre de la instancia en la que estamos trabajando
 * @returns El documento de la instancia
 */
const getInstance = async (instanceName: string): Promise<ClientSettings> => {
  const ref = doc(firestore, `Instances/${instanceName}`);
  const snap = await getDoc(ref);
  if (snap.exists()) {
    return snap.data() as ClientSettings;
  } else {
    throw "Instance does not exist"
  }
}

/**
 * Recuperar los Assignments del usuario que tenemos en la sesión
 * @param instance Nombre de la instancia en la que estamos trabajando
 * @param uid Usuario de la sesión
 * @returns Los assignments que le corresponden al usuario dado
 */
const getAssignments = async (
  instance: string,
  uid: string,
  cursoActivo: string
): Promise<AssignmentV2[]> => {
  const ref = collection(firestore, `Instances/${instance}/Assignments`);
  const q = query(ref, where("User", "==", uid), where("Course", "==", cursoActivo));
  const snap = await getDocs(q);
  if (snap.empty) return [];
  let data: AssignmentV2[] = snap.docs.map((x) => x.data() as AssignmentV2);
  return data;
};


/**
 * Recuperar un curso
 * @param instance Nombre de la instancia en la que estamos trabajando
 * @param courseID Curso que queremos recuperar
 * @returns Curso
 */
const getCourse = async (
  instance: string,
  courseID: string
): Promise<Course | null> => {
  const docRef = doc(firestore, `Instances/${instance}/Courses`, courseID);
  const snap = await getDoc(docRef);
  if (snap.exists()) {
    const course = snap.data() as Course;
    // console.log(course.Name);
    return course;
  }
  return null;
};

/**
 * Recuperar los módulos de un curso, por su ID
 * @param instance Nombre de la instancia en la que estamos trabajando
 * @param courseID Curso del cual queremos recuperar los módulos
 * @returns Arreglo de módulos del curso solicitado
 */
const getModules = async (
  instance: string,
  courseID: string
): Promise<Module[]> => {
  const ref = collection(
    firestore,
    `Instances/${instance}/Courses/${courseID}/Modules`
  );
  const q = query(ref, orderBy("Index"));
  let data: Module[] = await getDocs(q).then((snap) => {
    return (
      snap.docs
        // iterate docs to get modules
        .map((doc) => doc.data() as Module)
    );
  });
  return data;
};


/**
 * Recuperar el objeto de un usuario de Virtual University
 * @param instance Nombre de la instancia en la que estamos trabajando
 * @param userID ID del usuario que queremos recuperar
 * @returns El objeto User
 */
const getUser = async (instance: string, userID: string): Promise<User> => {
  // Con el UID de auth
  //const ref = doc(firestore, `Instances/${instance}/Users/${userID}`);
  //let user: User = (await (await getDoc(ref)).data()) as User;
  //return user;
  const ref = collection(firestore, `Instances/${instance}/Users`);
  const q = query(ref, where("UID", "==", userID));

  let data: User = await getDocs(q).then((snap) => {
    return snap.docs[0].data() as User;
  });
  return data;
};


const getStudyTips = async (instance: string): Promise<StudyTip[]> => {
  const ref = collection(firestore, `Instances/${instance}/StudyTips`);
  const q = query(ref, orderBy("From", "desc"));

  const snap = await getDocs(q);

  if (snap.empty) { return [] }
  let data: StudyTip[] = snap.docs.map((d: any) => d.data() as StudyTip)

  return data;
}