import {makeStyles, createStyles} from "@mui/styles";
import {Theme} from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		MuiDrawer: {
			backgroundColor: theme.palette.primary.main + " !important",
			border: "none !important",
		},
		listItem: {
			borderRadius: "100px 0 0 100px !important",
			marginTop: "12px !important",
			backgroundColor: "transparent !important",
		},
		listItemSelected: {
			borderRadius: "100px 0 0 100px !important",
			marginTop: "12px !important",
			backgroundColor: "#f2f2f2 !important",
		},
	})
);

export default useStyles;
