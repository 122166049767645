import React, { useState } from "react";
import {
  AccountCircleOutlined,
  Email,
  EmailOutlined,
  ExpandMore,
  Person,
  PersonOutline,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  InputAdornment,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { toTitleCase } from "../../../../../helpers/index";
import useStyles from "./style";
import { User } from "../../../../../interfaces";

interface Props {
  userCopia: User;
  user: User;
  handleChange: (value: User) => void;
  hasChanges: boolean;
  handleSave: (value: User) => void;
  setHasChanges: (Value: boolean) => void;
}

const UserProfileButton_DefaultView: React.FC<Props> = ({
  user,
  userCopia,
  handleChange,
  hasChanges,
  handleSave,
  setHasChanges,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [corrreoAux, setCorreoAux] = useState<string>(
    userCopia.Data.LeaderEmail
  );
  const [errEmail, setErrEmail] = useState<string>("");
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div className={classes.mainContainer} onClick={(e) => handleClick(e)}>
        <AccountCircleOutlined className={classes.icon} />
        <div className={classes.nameContainer}>
          <Typography sx={{ fontWeight: "bold" }}>
            {user.Data.PreferredName && user.Data.PreferredName !== ""
              ? toTitleCase(user.Data.PreferredName!)
              : toTitleCase(user.Name!)}
          </Typography>
          <Typography>{user.Email!}</Typography>
        </div>
        <ExpandMore />
      </div>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: { width: "35%" },
        }}
      >
        <Grid container className={classes.mainContainer}>
          <Grid item xs={12}>
            <Typography textAlign="center">Datos de la cuenta</Typography>
          </Grid>
          <TextField
            label="Nombre"
            value={userCopia.Name}
            variant="standard"
            fullWidth
            disabled
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Email"
            disabled
            value={userCopia.Email}
            variant="standard"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Nombre preferido"
            value={userCopia.Data.PreferredName}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              !userCopia.Data.PreferredName &&
              "¿Qué nombre quieres que aparezca en tu perfil?"
            }
            onChange={(e) =>
              handleChange({
                ...userCopia,
                Data: { ...userCopia.Data, PreferredName: e.target.value },
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircleOutlined />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Nombre de líder"
            value={userCopia.Data.LeaderName}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              !userCopia.Data.LeaderName && "¡Actualiza el nombre de tu líder!"
            }
            onChange={(e) =>
              handleChange({
                ...userCopia,
                Data: { ...userCopia.Data, LeaderName: e.target.value },
              })
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Email de líder"
            value={corrreoAux}
            variant="standard"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            helperText={
              !userCopia.Data.LeaderEmail && "¡Actualiza el email de tu líder!"
            }
            onChange={(e) => {
              if (emailRegex.test(e.target.value)) {
                setErrEmail("");
                handleChange({
                  ...userCopia,
                  Data: { ...userCopia.Data, LeaderEmail: e.target.value },
                });
              } else {
                setErrEmail("Email incorrecto");
                setHasChanges(false);
              }

              setCorreoAux(e.target.value);
            }}
            error={errEmail !== ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              ),
            }}
          />

          <Grid container item xs={12}>
            <Button
              fullWidth
              disabled={!hasChanges}
              onClick={() => handleSave(userCopia)}
            >
              Guardar
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default UserProfileButton_DefaultView;
