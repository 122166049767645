import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		size: {
			[theme.breakpoints.only("xs")]: {
				backgroundColor: "red",
			},
			[theme.breakpoints.only("sm")]: {
				backgroundColor: "brown",
			},
			[theme.breakpoints.only("md")]: {
				backgroundColor: "white",
			},
			[theme.breakpoints.only("lg")]: {
				backgroundColor: "blue",
			},
			[theme.breakpoints.only("xl")]: {
				backgroundColor: "black",
			},
		},
		mainContainer: {
			width: "100vw",
			height: "90vh",
			minHeight: "882px",
		},
		containerCalendar: {
			minHeight: "800px",
			maxWidth: "1000px",
		},
		containerItemTipsEstudio: {
			marginLeft: 20,
		},
		typographyTitulosTarjetas: {
			padding: "10px 0px 10px 10px",
		},
		typographyTitulosColor: {
			color: theme.palette.secondary.main,
		},
		divElementsOPS: {
			margin: "10px",
			minHeight: "653px",
			maxHeight: "653px",
			overflowY: "scroll",
			height: "100%",
		},
		divElementsTipsEstudio: {
			margin: "10px",
			// minHeight: "450px",
			maxHeight: "350px",
			overflowY: "scroll",
			height: "100%",
			padding: 20,
		},
		root: {
			animation: "fadeInRight .55s",
		},
		table: {
			"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
				outline: "none !important",
			},
			'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus': {
				outline: 'none !important'
			},
			'&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
				outline: 'none !important'
			}
		}
	})
);

export default useStyles;
