import { isDate, isEmpty } from 'lodash';
import * as XLSX from 'xlsx';
import { validationSheet, validationWorkbook } from '../../../../../interfaces/validation';

export const validarCargaCertificaciones = (sheets: { [sheet: string]: XLSX.WorkSheet }, nameSheets: string[]) => {
    let resultSheet: validationWorkbook = {}

    const hoja = XLSX.utils.sheet_to_json(sheets[nameSheets[0]], {
        header: 1,
        dateNF: 'mm-dd-yyyy hh:mm',
    }) as any[][];

    let validateDates = validateFechas(hoja);

    if (validateDates !== null) {
        resultSheet["Fechas_Certificacion"] = validateDates;
    }

    return resultSheet;
}

const validateFechas = (data: any[][]) => {
    let results: validationSheet = {};

    if (isEmpty(data)) {
        return results;
    } else {
        if (!data[0][0] || data[0][0].toString().toLowerCase().trim() !== "svc") {
            results["A1"] = {
                header: "SVC",
                rules: "El SVC debe estar en la columna A",
                details: "No se encontró el encabezado SVC",
                value: data[0][0]
            };
        }

        if (!data[0][1] || data[0][1].toString().toLowerCase().trim() !== "region") {
            results["B1"] = {
                header: "Región",
                rules: "La region debe estar en la columna B",
                details: "No se encontró el encabezado región",
                value: data[0][1],
            }
        }

        if (!data[0][2] || data[0][2].toString().toLowerCase().trim() !== "fecha") {
            results["C1"] = {
                header: "Fecha",
                rules: "La fecha debe estar en la columna C",
                details: "No se encontró el encabezado fecha",
                value: data[0][2],
            }
        }

        if (!data[0][3] || data[0][3].toString().toLowerCase().trim() !== "ubicacion") {
            results["E1"] = {
                header: "Ubicación",
                rules: "Ubicación debe estar en la columna D",
                details: "No se encontró el encabezado ubicación",
                value: data[0][4],
            }
        }

        if (!data[0][4] || data[0][4].toString().toLowerCase().trim() !== "lider") {
            results["F1"] = {
                header: "Líder",
                rules: "El lider debe estar en la columna E",
                details: "No se encontró el encabezado lider",
                value: data[0][4],
            }
        }

        if (!isEmpty(results)) return results;

        for (let i = 1; i < data.length; i++) {
            let svc = data[i][0],
                region = data[i][1],
                date = data[i][2],
                place = data[i][3],
                leader = data[i][4]

            if (svc === undefined) {
                results[`A${i + 1}`] = {
                    header: 'SVC',
                    details: 'Valor inválido',
                    value: svc,
                    rules: "El campo no debe estar vacío"
                }
            }

            if (region === undefined) {
                results[`B${i + 1}`] = {
                    header: 'Región',
                    details: 'Valor inválido',
                    value: region,
                    rules: "El campo no debe estar vacío"
                }
            }

            if (date === undefined || !isDate(date)) {
                results[`C${i + 1}`] = {
                    header: 'Date',
                    details: 'Valor inválido',
                    value: date,
                    rules: "El campo no debe estar vacío y no debe ser de tipo texto, debe respetar el formato mm/dd/YYYY hh:mm"
                }
            }

            if (place === undefined) {
                results[`D${i + 1}`] = {
                    header: 'Place',
                    details: 'Valor inválido',
                    value: place,
                    rules: "El campo no debe estar vacío"
                }
            }

            if (leader === undefined) {
                results[`E${i + 1}`] = {
                    header: 'Leader',
                    details: 'Valor inválido',
                    value: leader,
                    rules: "El campo no debe estar vacío"
                }
            }
        }
    }
    if (!isEmpty(results)) return results;
    else return null;
}