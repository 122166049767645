import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useAppContext } from "../../../../../auth/appContext";
import { UserContext } from "../../../../../auth/userContext";
import { firestore } from "../../../../../firebase";
import { User } from "../../../../../interfaces";
import BackdropComponent from "../../../../Backdrop";
import UserProfileButton_DefaultView from "./view";

const UserProfileButton_Default = () => {
  const { user } = useContext(UserContext);
  const { AppSettings } = useAppContext();
  const [userCopia, setUser] = useState<User>();
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setUser({ ...user });
    }
  }, [user]);

  const handleChange = (newData: User) => {
    setUser(newData);
    setHasChanges(true);
  };

  const handleSave = async (newData: User) => {
    const refUsers = collection(
      firestore,
      `Instances/${AppSettings.Name}/Users`
    );
    const docRef = doc(refUsers, `${newData.UID}`);

    let snapshot = await getDoc(docRef);

    if (snapshot.exists()) {
      await setDoc(docRef, newData);
    }

    setHasChanges(false);
  };

  if (userCopia) {
    return (
      <UserProfileButton_DefaultView
        user={user}
        userCopia={userCopia}
        handleChange={handleChange}
        hasChanges={hasChanges}
        handleSave={handleSave}
        setHasChanges={setHasChanges}
      />
    );
  } else {
    return <BackdropComponent />;
  }
};

export default UserProfileButton_Default;
