import { createContext, forwardRef, useContext, useState } from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import { Snackbar, SnackbarOrigin } from "@mui/material";
import { useIsMountedRef } from "../../hooks/isMountedRef";

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} variant='filled' ref={ref} {...props} />
});

type Severity = 'info' | 'error' | 'warning' | 'success';

interface MessageObject {
    severity: Severity;
    text: string;
    open?: boolean;
    duration?: number;
    position?: SnackbarOrigin;
}

interface MessageObjectContext {
    showMessage: (message: MessageObject) => void;
    data: MessageObject;
}

const MessageContext = createContext<MessageObjectContext>({
    showMessage: () => false,
    data: {
        severity: 'info',
        text: '',
        open: false,
    }
})

export const MessageProvider = ({ children }: any) => {
    const [state, setState] = useState<MessageObject>({
        severity: 'info',
        text: '',
        open: false,
    });

    const isMountedRef = useIsMountedRef();

    const showMessage = (message: MessageObject) => {
        setState({
            ...message,
            open: true,
            position: message.position || {
                horizontal: 'right',
                vertical: 'bottom',
            }
        });
    }

    const handleClose = (event: any, reason?: string) => {
        if (reason === 'clickaway')
            return;
        if (isMountedRef.current)
            setState({
                ...state,
                open: false,
            })
    }

    return <MessageContext.Provider value={{ showMessage, data: state }}>
        <Snackbar
            open={state.open}
            anchorOrigin={state.position}
            autoHideDuration={state.duration || 3000}
            onClose={handleClose}
        >
            <Alert onClose={handleClose} severity={state.severity}>
                {state.text}
            </Alert>
        </Snackbar>
        {children}
    </MessageContext.Provider>
}
export const useShowMessage = () => useContext(MessageContext);