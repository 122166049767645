import { child, get, push, ref } from "firebase/database";
import { db } from "../../firebase";

/**
 * Guardar un registro en analytics
 * @param instance
 * @param uid
 * @param obj
 */
export const saveAnalytics = async (
  instance: string,
  uid: string,
  obj: any
) => {
  const now = new Date();
  const day = now.toISOString().split("T")[0];
  let guardar = true;

  if (obj === "login") {
    // Verificar si no hay ya un registro login de este user
    guardar = await checkAnalytics(instance, uid);
  }

  if (guardar) {
    await push(ref(db, `Analytics/${instance}/${day}/${uid}`), obj);
  }
};

// const checkRegistro = async (
//   instance: string,
//   day: string,
//   uid: string,
//   registro: string
// ) => {
//   let userRef = `Analytics/${instance}/${day}/${uid}`;
//   let flag = false;

//   await get(child(ref(db), userRef)).then((snapshot) => {
//     if (snapshot.exists()) {
//       const data = snapshot.val();
//       let values: string[] = Object.values(data);

//       if (values.includes(registro.toString())) {
//         flag = true;
//       }
//     }
//   });

//   return flag;
// };

/**
 * Verificar si el user ya tiene un registro para ese día.
 * @param instance
 * @param uid
 * @returns
 */
export const checkAnalytics = async (instance: string, uid: string) => {
  const now = new Date();
  const day = now.toISOString().split("T")[0];
  const userRef = `Analytics/${instance}/${day}/${uid}`;
  let flag = true;
  await get(child(ref(db), userRef)).then((snapshot) => {
    if (snapshot.exists()) flag = false;
  });

  return flag;
};
