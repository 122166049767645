import withAuth from "../../../../auth/withAuth";
import RetaView from "./view";
import { useDataContext } from "../../../../auth/dataContext";
import { QuizMaterial } from "../../../../interfaces";
import { useState } from "react";

const RetaPage: React.FC = () => {
  const { state } = useDataContext();

  const [selectedQuiz, setSelectedQuiz] = useState<QuizMaterial | undefined>(
    undefined
  );

  const assignment = state.assignments2![0];
  if (assignment) {
    const currentCourseId = assignment.Course;

    let lastCompletedModuleIndex = -1;
    let lastCompletedTopicIndex = -1;

    assignment!.Progress.forEach((moduleProgress, index) => {
      if (moduleProgress.ModuleProgress >= 1) {
        lastCompletedModuleIndex = index;
      } else if (lastCompletedTopicIndex === -1) {
        lastCompletedTopicIndex = moduleProgress.Topics.findIndex(
          (topicProgress) => !topicProgress.Approved
        );
      }
    });

    const quizzesMaterial: QuizMaterial[] = [];
    state.modules[currentCourseId].forEach((module, index) => {
      if (index <= lastCompletedModuleIndex + 1) {
        module.Topics.forEach((topic, i) => {
          if (index <= lastCompletedModuleIndex || i < lastCompletedTopicIndex)
            topic.Material.forEach((material) => {
              if (material.Type === "test") {
                quizzesMaterial.push({
                  Name: material.Name,
                  Questions: material.Questions!,
                  ModuleName: module.Name,
                  TopicName: topic.Name,
                  ID: material.Name + "-" + index + "-" + i,
                });
              }
            });
        });
      }
    });

    return (
      <RetaView
        quizzes={quizzesMaterial}
        selectedQuiz={selectedQuiz}
        setSelectedQuiz={setSelectedQuiz}
      />
    );
  } else {
    return (
      <h2 className="fadeInRigth">
        Debes estar inscrito al curso activo para poder ver esta sección.
      </h2>
    );
  }
};

export default withAuth(RetaPage);
