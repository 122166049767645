import React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import {ModalInt} from "./interface";
import { useAppContext } from "../../auth/appContext";
interface Props {
	open: boolean;
	handleClose: () => void;
	customStyle?: any;
}

const ModalComponent: React.FC<Props> = ({open, handleClose, customStyle, children}) => {
	const {AppSettings} = useAppContext();

	const style: ModalInt = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "400px",
		bgcolor: "background.paper",
		border: "0px solid #000",
		boxShadow: 24,
		p: 4,
		borderRadius: AppSettings.DesignSettings.borderRadius !== undefined ? 
		`${AppSettings.DesignSettings.borderRadius}px` //Lo que diga la configuración de la instancia
		: "0px", //0 por default
		backgroundColor: "white",
	};

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			open={open}
			onClose={(e, reason) => (!reason ? handleClose() : null)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Fade in={open}>
				<Box sx={customStyle ? customStyle : style}>{children}</Box>
			</Fade>
		</Modal>
	);
};

export default ModalComponent;
