import {
  Card,
  CardActionArea,
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { PictureAsPdf, Share } from "@mui/icons-material";
import { StorageFile } from "../Interfaces";
import { Theme } from "@mui/system";
import { useAppContext } from "../../../../../auth/appContext";
import { getDownloadUrl } from "../../../../../helpers";
interface Props {
  fileDetail: StorageFile;
  setPreview: (obj: any) => void;
  setCurrentDoc: (obj: any) => void;
}

const DocumentCard: React.FC<Props> = ({
  fileDetail,
  setPreview,
  setCurrentDoc,
}) => {
  const { AppSettings } = useAppContext();

  // const handlecontent = () => {
  // getDownloadUrl(obj.URL!).then((url) => {
  //   setPreview(
  //     <Grid container direction="column">
  //       <Link href={url} target="_blank" rel="noopener">
  //         Abrir material en otra ventana
  //       </Link>
  //       <iframe
  //         style={{
  //           width: "100%",
  //           minHeight: 500,
  //         }}
  //         title={obj.Name}
  //         src={`${url}#view=FitH`}
  //       />
  //     </Grid>
  //   );
  // });
  // }

  return (
    <>
      <ListItemButton
        sx={{ margin: "2px", color: "black" }}
        onClick={() => {
          //window.open(fileDetail.Url, "_blank", "noopener,noreferrer");
          setCurrentDoc(fileDetail);
          getDownloadUrl(fileDetail.Url!).then((url) => {
            setPreview(
              <Grid container direction="column">
                <Link href={url} target="_blank" rel="noopener">
                  Abrir material en otra ventana
                </Link>
                <iframe
                  style={{
                    width: "100%",
                    minHeight: "80vh",
                  }}
                  title={fileDetail.Name}
                  src={`${url}#view=FitH`}
                />
              </Grid>
            );
          });
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body2" component="span">
              {fileDetail.Name}
            </Typography>
          }
        />
      </ListItemButton>
    </>
  );
};

export default DocumentCard;
