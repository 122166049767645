import ReportsView from "./view";
import { useState, useEffect } from "react";
import { useAppContext } from "../../auth/appContext";
import { SelectChangeEvent } from "@mui/material";
import { getCourses, getReports } from "./service";
import { Course } from "../../interfaces/course";
import { Report } from "../../interfaces";
import { useDataContext } from "../../auth/dataContext";
import { orderBy } from "lodash";


const ReportsPage = () => {
  const context = useAppContext();
  const { state } = useDataContext();
  const [selectedCourseIndex, setSelectedCourseIndex] = useState<number>(-1);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [courses, setCourses] = useState<Course[]>([]);
  const [reports, setReports] = useState<Report[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [courseID, setCourseID] = useState<string>("");
  const [resultReports, setResultReports] = useState<Report[]>([]);
  const [allReports, setAllReports] = useState<Report[]>([]);
  const handleSelectedReport = (report: Report) => {
    setSelectedReport(report);
  };

  const handleSelectedCourse = (event: SelectChangeEvent) => {
    setSelectedCourseIndex(Number(event.target.value));
    setSelectedReport(null);
  };

  const getAllReports = async () => {

    // Sequentially fetch reports for each course
    for (const course of courses) {

      try {
        const tReports = await getReports(context.AppSettings.Name, course.ID!, course.Date_Start);

        setAllReports((prevVal) => [...prevVal, ...tReports]);

      } catch (error) {
        console.error("Error fetching reports:", error);
      }
    }
    setResultReports(allReports.filter((report) => report.Type === "results"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getCourses(context.AppSettings.Name).then((data) => {
      const orderedCourses = orderBy(data, 'Name')
      setCourses(orderedCourses);
    });
  }, [context.AppSettings.Name]);

  useEffect(() => {
    if (selectedCourseIndex !== -1) {
      setLoading(true);
      getReports(context.AppSettings.Name, courses[selectedCourseIndex].ID!).then((data) => {
        setCourseID(courses[selectedCourseIndex].ID!);
        let reportes = data;

        // FILTRAR REPORTES EN BASE A ROL 
        // ! COMO MEJORA PODRÍA CONFIGURARSE DESDE ADMIN O HACERLO MÁS DINAMICO (EVITAR HARDCODE)
        if (state.user.Data.isManager)
          setReports(orderBy(filtrarReportes(reportes), 'Name'));
        else
          setReports(orderBy(reportes, 'Name'));

        setLoading(false);
      }).catch((e) => {
        console.log('error: ', e)
      });
    }
  }, [selectedCourseIndex, context.AppSettings.Name, courses, state.user.Data]);

  useEffect(() => {

    setAllReports([])
    getAllReports();

  }, [selectedCourseIndex, courses]);
  /**
   * Filtrar reportes a los que el usuario manager puede tener acceso
   * @param reportes arreglo de objetos reportes bd
   * @returns reportes filtrados para usuarios manager
   */
  const filtrarReportes = (reportes: Report[]) => {
    const reportesManager = ['Feedback SVC', 'Resultados Certificación',
      'Resultados por Antigüedad', 'Estudio por Participante', 'Uso de la plataforma'];

    return reportes.filter(reporte => reportesManager.includes(reporte.Name));
  }

  return (
    <ReportsView
      loading={loading}
      reports={reports}
      courses={courses}
      selectedCourseIndex={selectedCourseIndex}
      selectedReport={selectedReport}
      handleSelectedReport={handleSelectedReport}
      handleSelectedCourse={handleSelectedCourse}
      instanceName={context.AppSettings.Name}
      courseID={courseID}
      resultReports={resultReports}

    />
  );
};

export default ReportsPage;
