import { isDate, isEmpty } from 'lodash';
import * as XLSX from 'xlsx';
import { validationSheet, validationWorkbook } from '../../../../../../interfaces/validation';

export const validarCargaStudyTips = (sheets: { [sheet: string]: XLSX.WorkSheet }, nameSheets: string[]) => {
    let resultSheet: validationWorkbook = {}

    const hoja = XLSX.utils.sheet_to_json(sheets[nameSheets[0]], {
        header: 1,
        dateNF: 'mm-dd-yyyy hh:mm',
    }) as any[][];

    let validateStudyTips = validateStudyTip(hoja);

    if (validateStudyTips !== null) {
        resultSheet["studyTips"] = validateStudyTips;
    }

    return resultSheet;
}

const validateStudyTip = (data: any[][]) => {
    let results: validationSheet = {};

    if (isEmpty(data)) {
        return results;
    } else {
        if (!data[0][0] || data[0][0].toString().toLowerCase().trim() !== "titulo") {
            results["A1"] = {
                header: "Titulo",
                rules: "El titulo debe estar en la columna A",
                details: "No se encontró el encabezado Titulo",
                value: data[0][0]
            };
        }

        if (!data[0][1] || data[0][1].toString().toLowerCase().trim() !== "descripcion") {
            results["B1"] = {
                header: "Descripcion",
                rules: "La descrpicion debe estar en la columna B",
                details: "No se encontró el encabezado Descripcion",
                value: data[0][1],
            }
        }

        if (!data[0][2] || data[0][2].toString().toLowerCase().trim() !== "desde") {
            results["C1"] = {
                header: "Desde",
                rules: "Desde debe estar en la columna C",
                details: "No se encontró el encabezado desde",
                value: data[0][2],
            }
        }

        if (!isEmpty(results)) return results;

        for (let i = 1; i < data.length; i++) {
            let titulo = data[i][0],
                descrpicion = data[i][1],
                desde = data[i][2]

            if (titulo === undefined || titulo.trim() === '') {
                results[`A${i + 1}`] = {
                    header: 'Titulo',
                    details: 'Valor inválido',
                    value: titulo,
                    rules: "El campo no debe estar vacío"
                }
            }

            if (descrpicion === undefined || descrpicion.trim() === '') {
                results[`B${i + 1}`] = {
                    header: 'Descripción',
                    details: 'Valor inválido',
                    value: descrpicion,
                    rules: "El campo no debe estar vacío"
                }
            }

            if (desde === undefined || !isDate(desde)) {
                results[`C${i + 1}`] = {
                    header: 'Date',
                    details: 'Valor inválido',
                    value: desde,
                    rules: "El campo no debe estar vacío y no debe ser de tipo texto, debe respetar el formato mm/dd/YYYY hh:mm"
                }
            }
        }
    }
    if (!isEmpty(results)) return results;
    else return null;
}