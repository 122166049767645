import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { DrawerItem } from "../../../../interfaces/drawer";
import { getIcon } from "../../../../helpers/iconHelper";
import { fbAuth } from "../../../../firebase";
import { signOut } from "@firebase/auth";
import { useDataContext } from "../../../../auth/dataContext";
import useStyles from "./style";
import { AppContext } from "../../../../auth/appContext";
import { Timestamp } from "firebase/firestore";
import ArticleIcon from '@mui/icons-material/Article';
const instanceName = "DHL";

const allItems: any[] = [
  {
    title: "Inicio",
    path: "/Home",
    hidden: "admin",
    key: 1,
    icon: getIcon(instanceName, "home", "outlined"),
    disabled: false,
  },
  {
    title: "Agenda de estudio",
    path: "/Calendar",
    hidden: "admin|courier",
    key: 2,
    icon: getIcon(instanceName, "calendar", "outlined"),
    disabled: false,
  },
  {
    title: "Learning HUB",
    path: "/Study",
    hidden: "admin|courier",
    key: 3,
    icon: getIcon(instanceName, "summary", "outlined"),
    disabled: false,
  },
  {
    title: "Actividades",
    path: "/Activities",
    hidden: "admin|courier",
    key: 5,
    icon: getIcon(instanceName, "activities", "outlined"),
    disabled: false,
  },
  {
    title: "Rétate a ti mismo",
    path: "/Reta",
    hidden: "admin|courier",
    key: 6,
    icon: getIcon(instanceName, "reta", "outlined"),
    disabled: false,
  },
  {
    title: "¿Estás listo?",
    path: "/AreYouReady",
    hidden: "admin|courier",
    key: 7,
    icon: getIcon(instanceName, "areuready", "outlined"),
    disabled: false,
  },
  {
    title: "Anuncios",
    path: "/Announcements",
    hidden: "admin",
    key: 8,
    icon: getIcon(instanceName, "announcements", "outlined"),
    disabled: false,
  },
  {
    title: "Reportes",
    path: "/Reports",
    hidden: "courier|leader|yis",
    key: 10,
    icon: getIcon(instanceName, "analytics", "outlined"),
    disabled: false,
  },
  {
    title: "Catálogo",
    path: "/Catalog",
    hidden: "admin|courier",
    key: 11,
    icon: getIcon(instanceName, "catalog", "outlined"),
    disabled: false,
  },
  {
    title: "Administrador",
    path: "/Admin",
    hidden: "courier|leader|yis|manager",
    key: 12,
    icon: getIcon(instanceName, "admin", "outlined"),
    disabled: false,
  },
  {
    title: "Documentos Importantes",
    path: "/Documents",
   // hidden: "courier|leader|yis|manager|", Así debería de ser
    hidden: "courier|leader|yis|manager|admin|tester|", // Escondido temporalmente 27/06/2024
    key: 13,
    icon: <ArticleIcon/>,
    disabled: false,
  },
  {
    title: "Cerrar sesión",
    path: "/Logout",
    hidden: "",
    key: 14,
    icon: getIcon(instanceName, "logout", "outlined"),
    disabled: false,
  },
];

export const AppbarSX = {
  background:
    "linear-gradient(90deg, rgba(255,204,0,1) 10%, rgba(255,204,0,0.5) 70%, rgba(255,204,0,0) 90%)",
  elevation: 0,
};

export const GetPageTitle = (selected: string) => {
  if (selected === "/Reports") {
    return "Reportes";
  } else if (selected === "/Calendar") {
    return "Agenda de estudio";
  } else {
    return allItems.find((item) => item.path === selected)?.title;
  }
};

interface Props {
  selected: string;
}

//#region DRAWER ITEMS - NAVIGATION
export const GetDrawerItems: React.FC<Props> = ({ selected }) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useDataContext();
  const { AppSettings } = useContext(AppContext);
  const [drawerItems, setDrawerItems] = useState<DrawerItem[]>([]);

  useEffect(() => {
    if (state.user.Data) {
      let role = "";
      if (state.user.Data.isCourier) {
        role = "courier";
      } else if (state.user.Data.isLeader) {
        role = "leader";
      } else if (state.user.Data.isManager) {
        role = "manager";
      } else if (state.user.Data.isAdmin) {
        role = "admin";
      } else if (state.user.Data.isTester) {
        role = "tester";
      } else if (state.user.Data.isYIS) {
        role = "yis";
      }

      let _items;

      if (!['admin', 'tester', 'manager'].includes(role)) {
        _items = allItems.filter(
          (x) => !x.hidden.includes(role)).map(
            (y) => {
              const item: DrawerItem = {
                ...y,
                hidden: false,
                disabled: verificarFechas(),
              };

              return item;
            });
      } else {
        _items = allItems.filter(
          (X) => !X.hidden.includes(role)).map(
            (routeItem) => {
              const item: DrawerItem = {
                ...routeItem,
                hidden: false,
              };

              return item;
            })
      }

      setDrawerItems(_items);
    }
  }, [state]);

  const logout = () => {
    signOut(fbAuth);
  };

  const studyPaths = [
    "/calendar",
    "/content",
    "/exercises",
    "/reta",
    "/activities",
    "/areyouready",
  ];

  const handleSelectRoute = (path: string) => {
    /**
     * Hay cursos a los que estemos inscritos? (que haya Assignment)
     */
    const hayCursos = Object.keys(state.courses).length > 0;
    if (hayCursos) {
      //Revisar si la plataforma está abierta todavía para estudiar
      const limitStudyDate = state.assignments2![0].Data.CertificationInfo.Date.toDate();
      const isOpen = limitStudyDate && limitStudyDate < new Date();
      if (isOpen &&
        studyPaths.includes(path.toLowerCase()) &&
        !state.user.Data.isAdmin &&
        !state.user.Data.isManager &&
        !state.user.Data.isTester) {
        //Es una ruta para estudiar, pero la plataforma está cerrada
        alert("Tiempo de estudio finalizado");
      } else {
        //Se puede navegar
        navigate(path);
      }
    } else {
      //Se puede navegar
      navigate(path);
    }
  };

  const verificarFechas = () => {
    const fechaLimite = AppSettings.Data.courseDeadline as Timestamp;
    if (fechaLimite.toDate() < new Date()) {
      return true;
    }
    return false
  }

  if (drawerItems.length === 0) {
    return <></>;
  } else {
    return (
      <List
        sx={{
          paddingLeft: "12px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div>
          {drawerItems.map((item, index) => {
            if (index !== drawerItems.length - 1)
              return (
                <Tooltip title={item.title} key={index}>
                  <ListItem
                    className={
                      item.path === selected
                        ? classes.listItemSelected
                        : classes.listItem
                    }
                    button
                    key={item.key}
                    disabled={item.disabled}
                    onClick={() => handleSelectRoute(item.path)}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.secondary.main,
                        transform: "scale(1.1)",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.title} />
                  </ListItem>
                </Tooltip>
              );
            else return null;
          })}
        </div>
        {/* LOGOUT ITEM - hasta abajo y blanco */}
        <ListItem
          style={{
            marginBottom: 16,
          }}
          className={classes.listItem}
          button
          onClick={logout}
        >
          <ListItemIcon
            sx={{
              color: "white",
            }}
          >
            {drawerItems[drawerItems.length - 1].icon}
          </ListItemIcon>
          <ListItemText primary={drawerItems[drawerItems.length - 1].title} />
        </ListItem>
      </List>
    );
  }
};

//#endregion DRAWER ITEMS - NAVIGATION
