import React, { useState } from "react";
import { MaterialItem, Question } from "../../../../../interfaces/course";
import { AssignmentV2 } from "../../../../../interfaces/assignment";
import { ResultTest } from "../../../../../interfaces/ui";
import _ from "lodash";
import PresentingTest_View from "./view";
import { saveAttemptFB } from "../../../../../helpers";
import { useAppContext } from "../../../../../auth/appContext";

interface props {
  assignment: AssignmentV2 | undefined;
  topicIndex: number;
  moduleIndex: number;
  material: MaterialItem;
  minScore: number;
  updateProgress: (
    moduleIndex: number,
    topicIndex: number,
    started: boolean,
    approved: boolean
  ) => void;
  back: () => void;
  handleReset: () => void;
  examen: boolean;
}

const PresentingTest: React.FC<props> = ({
  assignment,
  topicIndex,
  moduleIndex,
  material,
  minScore,
  updateProgress,
  back,
  handleReset,
  examen,
}) => {
  let { AppSettings } = useAppContext();
  const [questions, setQuestions] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isApprove, setIsApprove] = useState<boolean>(false);
  const [calificacion, setCalificacion] = useState<number>(0);
  const [correctAnsw, setCorrectAnsw] = useState<number>(0);
  const [results, setResults] = useState<ResultTest[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [temasReforzar, setTemasReforzar] = useState<any>(); //para ¿estás listo?
  const [showtemasReforzar, setShowTemasReforzar] = useState<boolean>(false); //para ¿estás listo?

  /**
   * restaurar los valores predeterminados de los estados
   */
  const reset = () => {
    setQuestions([]);
    setIsApprove(false);
    setCalificacion(0);
    setOpenModal(false);
    setResults([]);
    setShowResults(false);
    setCorrectAnsw(0);
    setTemasReforzar(undefined);
    setShowTemasReforzar(false);
  };

  /**
   * Almacena las respuestas del usuario en el state questions
   * @param idA indice de la respuesta
   * @param idQ indice de la pregunta
   */
  const handleAnswer = (idA: string, idQ: number) => {
    let tempQ = [...questions];
    //respetar los indices de la pregunta en el examen
    // puede que tenga campos con undefined, validar en vista
    tempQ[idQ] = idA;
    setQuestions(tempQ);
  };

  /**
   * Inicia el proceso de calificacion del examen
   * si es positivo actualiza la bd en fireStore
   * de lo contrario reinicia el modulo (APLICA SOLO CUANDO LA SECCIÓN ES EL TEST DE UN TEMA)
   */
  const calificarExamen = () => {
    let temasReforzar: any = {}; //para ¿Estás listo?
    let respuestas: number = 0;
    let calif: number = 0;
    let aprobado: boolean = false;
    let tempResult: ResultTest[] = []; //para almacenar respues correctas/incorrectas

    //*por cada respuesta almacenada en fireStore evaluamos contra el state questions
    (material.Questions ?? []).forEach((item: Question, index) => {
      if (item.Answer.toString() === questions[index]) {
        respuestas = respuestas + 1;
        tempResult.push({
          question: item,
          approved: true,
          userAnswer: item.Options[Number(questions[index])],
        });
      } else {
        tempResult.push({
          question: item,
          approved: false,
          userAnswer: item.Options[Number(questions[index])],
        });

        // la pregunta solo tiene module y topic cuando la vista es ¿Estás listo?
        if (item.Module) {
          temasReforzar[item.Module] = {
            ...temasReforzar[item.Module],
            [item.Tema!]: 0,
          };
        }
      }
    });

    //se calcula la calificacion y se almacena en un state
    calif = (respuestas * 100) / (material.Questions ?? []).length;
    setCorrectAnsw(respuestas);
    setCalificacion(calif);
    setResults(tempResult);

    //Evaluamos si se aprobo contra el parametro minScore
    //que representa el minimo para aprobar
    if (calif >= minScore) {
      aprobado = true;
    } else {
      aprobado = false;
    }

    /** ACTUALIZAR EL PROGRESO DE UN ASSIGMENT SI NO ESTÁ APROBADO Y REGISTRAR INTENTO DE EXAMEN.
     * NOTA: si es la sección ¿Estás listo? o Rétate a ti mismo, o si es la vista previa de un curso (/Course),
     * porque no va a haber assignment, será undefined. **/
    if (
      assignment &&
      assignment.Progress[moduleIndex].Topics[topicIndex].Approved === false
    ) {
      // ¿Aprobó?
      if (aprobado) {
        // Guarda avance en BD
        updateProgress(moduleIndex, topicIndex, true, true);
      }
      // Guarda intento de examen
      saveAttempt(aprobado, calif);
    }

    setIsApprove(aprobado);

    // CALCULAR TEMAS PARA REPASAR EN SECCIÓN ¿ESTÁS LISTO?
    if (temasReforzar && !_.isEmpty(temasReforzar)) {
      caltemasReforzar(temasReforzar, tempResult);
    }
  };

  const caltemasReforzar = (temasReforzar: any, resultTest: ResultTest[]) => {
    // verificar cuantas preguntas sacaron mal de un tema
    resultTest.forEach((rt) => {
      if (!rt.approved) {
        let topic = rt.question.Tema!;
        temasReforzar[rt.question.Module!][topic] += 1;
      }
    });

    setTemasReforzar(temasReforzar);
  };

  /**
   * inicia el proceso de calificacion del examen
   * evalua si todas las preguntas fueron respondidas
   */
  const endTest = () => {
    if (questions.length === (material.Questions ?? []).length) {
      calificarExamen();
      setOpenModal(true);
    } else {
      //   no deberia pasar, por el disabled del botón
    }
  };

  /**
   * Guarda el intento del usuario para pasar el exámen.
   * @param passed ¿lo aprobó o no lo aprobó?
   * @param score calificación del exámen
   */
  const saveAttempt = async (passed: boolean, score: number) => {
    if (assignment) {
      let attempts =
        assignment.Progress[moduleIndex].Topics[topicIndex].AttemptsToApprove ??
        [];

      attempts.push({
        Date: new Date(),
        Passed: passed,
        Score: Math.round(score),
      });

      let newAssigment = { ...assignment };
      newAssigment.Progress[moduleIndex].Topics[topicIndex].AttemptsToApprove =
        attempts;

      await saveAttemptFB(AppSettings.Name, newAssigment);
    }
  };

  return (
    <PresentingTest_View
      material={material}
      handleAnswer={handleAnswer}
      questions={questions}
      endTest={endTest}
      setOpenModal={setOpenModal}
      openModal={openModal}
      isApprove={isApprove}
      calificacion={calificacion}
      correctAnsw={correctAnsw}
      examen={examen}
      back={back}
      handleReset={handleReset}
      reset={reset}
      setShowResults={setShowResults}
      showResults={showResults}
      results={results}
      temasReforzar={temasReforzar}
      setShowTemasReforzar={setShowTemasReforzar}
      showtemasReforzar={showtemasReforzar}
    />
  );
};

export default PresentingTest;
