import { useEffect, useState } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
    Grid,
    Box,
    Toolbar,
    CssBaseline,
    Typography,
    LinearProgress,
    Fade,
    Avatar as AvatarPic,
} from '@mui/material'
import { styled } from "@mui/material/styles";
import InnovationLogo from '../../../../../../assets/images/InnovationLogo.png'
import { useAppContext } from "../../../../../../auth/appContext";
import { GetAppbarElevation } from "../../../../../../components/Drawer/Instances";
import logoDHL from './logoDHL.png';
import { useParams } from "react-router-dom";
import { db } from "../../../../../../firebase";
import { onValue, push, ref, set } from "firebase/database";
import { Avatar, Participante, Reta } from "../../../../../../interfaces/reta";
import SignIn from "./SignIn";
import Sorry from "./Sorry";
import GameQuiz from "./GameQuiz";
import _ from "lodash";
import useStyles from "./GameQuiz/style";
import { getStorage, listAll, ref as storageRef } from "firebase/storage";
import { getDownloadUrl } from "../../../../../../helpers";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface ParticipanteFinal {
    id?: string;
    tiempoResponder: number;
    respuestas: number;
    nombre: string;
    avatar: string;
}

const GameQuizClient = () => {
    const { gameRoomID } = useParams();
    const { AppSettings } = useAppContext();
    const [nombreEstudiante, setNombreEstudiante] = useState<string>("");
    const [quiz, setQuiz] = useState<Reta>();
    const [enable, setEnable] = useState<boolean>(false);
    const [signedIn, setSignedIn] = useState<boolean>(false);
    const [selectedAvatar, setSelectedAvatar] = useState<number>();

    const [participante, setParticipante] = useState<Participante>()
    const [contador, setContador] = useState<number>(0);
    const [selected, setSelected] = useState<number>(-1);
    const [tiempo, setTiempo] = useState<number>(0);
    const [key, setKey] = useState<string>('');
    const [finished, setFinished] = useState<boolean>(false);
    const [resultados, setResultados] = useState<ParticipanteFinal[]>([]);
    const storage = getStorage();
    const classes = useStyles(); 
    const [avatars] = useState<Avatar[]>([])

    const contentMarginLeft = "0px";

    /**
     * Inscribir al usuario y guardarlo en bd
     */
    const handleInscribir = async () => {
        if (quiz) {
            try {
                let tempExamen = quiz?.exam;
                tempExamen!.forEach((pregunta) => {
                    delete pregunta.Options;
                    delete pregunta.Name;
                    pregunta.Answer = -1;
                });

                let participanteNuevo: Participante = {
                    Name: nombreEstudiante,
                    Examen: quiz.exam,
                }

                setParticipante(participanteNuevo)

                let temp = await push(ref(db, `${AppSettings.Name}/GameQuiz/${gameRoomID}/participantes`), participanteNuevo)
                setSignedIn(true);
                setKey(temp.key!)
            }
            catch (e) {
                console.log(e);
            }
        }
    }

    /**
     * Listener de gameroom
     */
    useEffect(() => {
        if (gameRoomID && gameRoomID.trim() !== "") {
            const retaRef = ref(db, `${AppSettings.Name}/GameQuiz/${gameRoomID}`);
            onValue(retaRef, (snapShot) => {
                const data: Reta = snapShot.val();
                setQuiz(data);
            })
        }
    }, []);

    /**
     * Listener para funciones
     */
    useEffect(() => {
        if (quiz && quiz.realtimeOpts) {
            setTiempo(performance.now());
            setContador(contador + 1);
            setSelected(-1);
        }
        if (contador == quiz?.exam.length) {
            subirResultados().then(() => {
                setFinished(true);
                setResultados(obtenerResultados());
            })
        }
    }, [quiz, quiz?.realtimeOpts]);

    /**
     * sube los resultados de la reta a bd
     */
    const subirResultados = async () => {
        setParticipante({ ...participante!, Avatar: avatars[selectedAvatar ?? 0].img })
        let newRef = await set(ref(db, `${AppSettings.Name}/GameQuiz/${gameRoomID}/participantes/${key}`), participante);
    }

    /**
     * obtiene resultados finales
     */
    const obtenerResultados = () => {
        if (quiz && quiz.participantes) {
            var nuevaLista: ParticipanteFinal[] = [];
            const keys = Object.keys(quiz?.participantes);
            keys.forEach((id) => {
                let respuestas = 0;
                let tiempoTotalResponder = 0;
                let objeto: ParticipanteFinal = {
                    id: "",
                    nombre: "",
                    respuestas: 0,
                    tiempoResponder: 0,
                    avatar: avatars[selectedAvatar ?? 0].img,
                };
                quiz.participantes[id].Examen?.forEach((pregunta, index) => {
                    tiempoTotalResponder = tiempoTotalResponder + (pregunta.tiempoResponder ?? 0);
                    if (quiz.exam[index].Answer.toString() === pregunta.Answer.toString()) {
                        respuestas = respuestas + 1;
                    }
                })
                objeto.id = id;
                objeto.nombre = quiz.participantes[id].Name;
                objeto.respuestas = respuestas;
                objeto.tiempoResponder = tiempoTotalResponder;
                nuevaLista.push(objeto);
            })

            let ordenado = _.orderBy(nuevaLista, ['respuestas', 'tiempoResponder'], ['asc', 'desc']);
            ordenado = _.reverse(ordenado)

            return ordenado;
        } else {
            return [];
        }
    }

    /**
     * Selecciona la pregunta y establece el contador de tiempo
     * @param e index de pregunta
     */
    const selectPregunta = (e: number) => {
        let finalTime = endTimer(tiempo);
        setSelected(e);
        let examen = participante!.Examen!
        examen[contador - 1].Answer = e;
        examen[contador - 1].tiempoResponder = Math.round(finalTime);
        setParticipante({ ...participante!, Examen: examen });
    }

    const endTimer = (e: number) => {
        let endTime = performance.now();
        let timeDiff = endTime - e;
        timeDiff /= 1000;
        return timeDiff;
    }

    /**
     * Verificar que no haya nombres vacíos
     */
    useEffect(() => {
        if (nombreEstudiante.trim() === "")
            setEnable(false)
        else
            setEnable(true)
    }, [nombreEstudiante]);

    /**
     * Selecciona avatar
     * @param index indice del avatar
     */
    const selectAvatar = (index: number) => {
        setSelectedAvatar(index);
    }

    /**
     * Obtener los avatars almacenados en storage
     */
    useEffect(() => {
        const st = storageRef(storage, 'Shared/Avatars');
        listAll(st).then((res) => {
            res.items.forEach((item) => {
                getDownloadUrl(item.fullPath).then((url) => {
                    let newAvatar: Avatar = {
                        img: url,
                        nombre: item.name
                    }
                    avatars.push(newAvatar)
                })
            })
        })
    }, [])

    const getContent = () => {
        if (quiz) {
            if (quiz.open && !signedIn) {
                return (
                    <SignIn quiz={quiz} enable={enable} handleInscribir={handleInscribir} nombreEstudiante={nombreEstudiante} setNombreEstudiante={setNombreEstudiante} />)
            } else if (quiz.open && signedIn) {
                return (
                    <Fade in={true} timeout={400}>
                        <Grid container maxWidth="xs">
                            <Grid item xs={12}>
                                <Typography variant="h6" fontWeight="bold">
                                    Bienvenid@ {nombreEstudiante} a la reta de "{quiz.module}"!
                                </Typography>
                                <Typography>
                                    Por favor espera mientras tus compañeros se unen y/o el instructor
                                    inicia la sesión...
                                </Typography>
                            </Grid>
                            <Fade in={true} timeout={1500}>
                                <Grid item xs={12} marginTop={1}>
                                    Puedes elegir un avatar para identificarte mientras tanto:
                                    <Grid container sx={{ marginTop: 1 }} justifyContent="center">
                                        {avatars.map((avatar, index) => (
                                            <Grid item xs={3} key={index} margin={1}>
                                                <AvatarPic
                                                    sx={{ cursor: 'pointer', width: "100%", height: "100%" }}
                                                    alt={avatar.nombre}
                                                    src={avatar.img}
                                                    className={index === selectedAvatar ? classes.flicker : undefined}
                                                    onClick={() => { selectAvatar(index) }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Fade >)
            } else if (!quiz.open && !signedIn) {
                return (<Sorry />)
            } else if (!quiz.open && signedIn) {
                return (<GameQuiz quiz={quiz} selectPregunta={selectPregunta} selected={selected} finished={finished} idInscrito={key} resultados={resultados} />)
            } else {
                return (<Grid>nada</Grid>)
            }
        } else {
            return (
                <Box component='main' sx={{ flexGrow: 1, p: 1, marginLeft: contentMarginLeft }}>
                    <DrawerHeader />
                    <Grid container justifyContent="center" alignContent="center">
                        <LinearProgress color="secondary" sx={{ width: '100%' }} />
                    </Grid>
                </Box>
            )
        }
    }

    return (
        <div style={{ position: "relative" }}>
            <div style={{ margin: "0px", minHeight: "300px", marginBottom: "20px", }}>
                <CssBaseline />
                <AppBar position='fixed'
                    elevation={GetAppbarElevation(AppSettings.Name)}
                    style={{ backgroundColor: 'white' }}
                >
                    <div style={{ background: "linear-gradient(90deg,#fc0 0,#ffe57f 80%,#fff0b2)", }}>
                        <Toolbar>
                            <Grid container justifyContent='flex-start' alignItems='center'>
                                <img style={{ width: "100px" }} src={logoDHL} alt='logo' className={classes.animacion} />
                                {/* <Typography fontWeight='bold' color='inherit' variant='inherit'>
                                    Reta
                                </Typography> */}
                            </Grid>
                        </Toolbar>
                    </div>
                </AppBar>
                <Box component='main' sx={{ flexGrow: 1, p: 3, marginLeft: contentMarginLeft }}>
                    <DrawerHeader />
                    <Grid container justifyContent="center" alignContent="center">
                        {getContent()}
                    </Grid>
                </Box>
                <Grid container style={{ height: 150 }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-end",
                        position: "absolute",
                        bottom: "0px",
                        width: "100%",
                    }}>
                        <img
                            hidden={window.location.pathname === "/"}
                            style={{
                                width: "40%",
                            }}
                            src={InnovationLogo}
                            alt='innovation_logo'
                        />
                    </div>
                </Grid>
            </div>
        </div>
    )
}

export default GameQuizClient;