import { createStyles, makeStyles } from "@mui/styles";
import { lighten, Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxHeight: 200,
    },
    cardImage: {
      alignSelf: "center",
    },
    cardContent: {
      padding: 10,
      
    },
    padded: {
      padding: 10,
      width: '100%',
      height: "100%"
    },
    root: {
      animation: "fadeInRight .4s",
    },
    submitButton: {
      // borderRadius: "100px !important",
      // color: `${theme.palette.secondary.contrastText} !important`,
      // padding: ".5rem 5rem !important",
      "&:disabled": {
        opacity: "0.5 !important",
      },
    },
    checkIcon: {
      animation:
        "$scaleBounceIn 1.5s .8s forwards, $sideToSide .5s 2.5s alternate infinite",
      fontSize: "8rem !important",
      transform: "scale(0)",
    },
    blockDivider: {
      backgroundColor: theme.palette.grey[300],
      padding: "8px 4px",
      fontWeight: "500",
      textAlign: "center",
    },
    fadeInUp: {
      animation: "$fadeInUp .8s 2.5s forwards",
      opacity: "0",
      pointerEvents: "none",
    },
    fadeInDown: {
      animation: "$fadeInDown .8s forwards",
      opacity: "0",
    },
    iconContainer: {
      alignItems: "center",
      backgroundColor: lighten(theme.palette.grey[800], 0.75),
      color: theme.palette.secondary.main,
      display: "flex",
      justifyContent: "center",
      padding: 32,
      transition: ".3s !important",
      "& :first-child": {
        fontSize: "3.5rem",
      },
    },
    "@keyframes fadeInUp": {
      from: {
        opacity: 0,
        transform: "translate3d(0, 30px, 0)",
      },
      to: {
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
        pointerEvents: "auto",
      },
    },
    "@keyframes fadeInDown": {
      from: {
        opacity: 0,
        transform: "translate3d(0, -30px, 0)",
      },
      to: {
        opacity: 1,
        transform: "translate3d(0, 0, 0)",
      },
    },
    "@keyframes scaleBounceIn": {
      "0%": {
        opacity: 0,
        transform: "scale(0) rotateZ(45deg)",
      },
      "50%": {
        opacity: 1,
        transform: "scale(1.3) rotateZ(0deg)",
      },
      "70%": {
        transform: "scale(1.3)",
      },
      "80%": {
        transform: "scale(.9)",
      },
      "90%": {
        transform: "scale(1.03)",
      },
      "100%": {
        transform: "scale(1)",
      },
    },
    "@keyframes sideToSide": {
      "0%": {
        transform: "scale(1)",
      },
      "100%": {
        transform: "scale(1.1)",
        filter: "drop-shadow(2px 8px 1px rgba(0,0,0,0.2))",
      },
    },
    radio: {
      "&$checked": {
        color: theme.palette.grey[900],
      },
    },
    checked: {},
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
      },
    },
  })
);

export default useStyles;
