import React from "react";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import RouterComponent from './components/Router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import './App.css';
import { WebsiteContextLoader } from './auth/appContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MessageProvider } from "./providers/MessageProvider/MessageProvider";

let releaseStage = "development";
if (!window.location.host.includes("localhost")) {
  releaseStage = "production";
}

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    appVersion: process.env.REACT_APP_VERSION,
    releaseStage: releaseStage,
    enabledReleaseStages: ["production"],
    apiKey: `${process.env.REACT_APP_BUGSNAG_API_KEY}`,
    plugins: [new BugsnagPluginReact()],
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: false,
    },
    onError: (event) => {
      //Omite errores
      // let omitir = bugsnag_filter(event);
      // if (omitir) return false;
      // else {
      event.addMetadata("Custom", {
        host: window.location.host,
        width: window.innerWidth,
        height: window.innerHeight,
      });
      // }
    },
  });
}

const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <WebsiteContextLoader>
          <MessageProvider>
            <RouterComponent />
          </MessageProvider>
        </WebsiteContextLoader>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}

export default App;