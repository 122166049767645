import { useEffect, useState } from "react";
import { getAuth, getRedirectResult, onAuthStateChanged } from "firebase/auth";
import { fbApp, fbFunctions } from "../firebase";
import { useAppContext } from "../auth/appContext";
import { useDataContext } from "../auth/dataContext";
import { UserProvider } from "../auth/userContext";
import { User } from "../interfaces";
import Backdrop from "../components/Backdrop";
import LogRocket from "logrocket";
import { AuthHandler } from "./Instances";
import { httpsCallable } from "firebase/functions";
import PublicRouter from "../components/RouterPublic";
import Bugsnag from "@bugsnag/js";
import { useShowMessage } from "../providers/MessageProvider/MessageProvider";

const withAuth = (Component: any) => {
  const WrappedComponent = () => {
    const { AppSettings } = useAppContext();
    const { state, dispatch } = useDataContext();
    const [user, setUser] = useState<User | undefined>(undefined);
    const { showMessage } = useShowMessage();
    const auth = getAuth();

    /**
     * Método que recibe el resultado de la autenticación con redirect (Microsoft, Google, etc)
     */
    getRedirectResult(auth)
      .then(async (res) => {
        if (res !== null) {
          const claim = httpsCallable(fbFunctions, "claim");
          await claim({ instance: AppSettings.Name });
          //Refresh current token, as it may not have the custom claims
          res.user.getIdToken(true);
        }
      })
      .catch((e) => {
        console.log("Error RedirectResult", e);
      });

    /**
     * Esto se ejecuta cada que cambia el estado de auth, es decir signIn, signOut
     */
    useEffect(
      () => {
        const auth = getAuth(fbApp);
        onAuthStateChanged(auth, async (u) => {
          if (u) {
            try {
              const res = await AuthHandler(
                AppSettings.Name,
                u,
                state,
                AppSettings.Config.Domains ?? []
              );
              if (res !== undefined) {
                if (res.user) {
                  //El usuario existe en la instancia
                  setUser({ ...res.user, logged: true });
                  dispatch({ type: "set", values: res.state });
                } else {
                  //! Error, si es que AuthHandler de la instancia no auto-registra usuarios nuevos
                }
              } 
            } catch (error) {
              showMessage({
                text: `Se detecto un cambio de permisos por favor inicia sessión nuevamente`,
                severity: 'info',
            });
            
              setUser({
                logged: false,
              });
            }
          } else {
            setUser({
              logged: false,
            });
          }
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    if (user === undefined) {
      //Render de un Backdrop (spinner de loading)
      return <Backdrop />;
    } else if (user.logged) {
      //Si no estamos en localhost, iniciar LogRocket
      if (!window.location.href.includes("localhost")) {
        try {
          LogRocket.init("5y8r44/virtualuniversity-q9vkv");
          LogRocket.identify(user.UID!, {
            name: user.Name!,
            email: user.Email!,

            // Add your own custom user variables here, ie:
            instance: AppSettings.Name,
          });
          Bugsnag.setUser(user.UID, user.Email, user.Name);
        } catch (e) {
          console.log("LogRocket setup error");
        }
      }

      //Render del contenido, envuelto en un contexto de usuario
      return (
        <UserProvider user={user} update={setUser}>
          <Component />
        </UserProvider>
      );
    } else {
      //Public routes
      return <PublicRouter />;
    }
  };

  return WrappedComponent;
};

export default withAuth;
