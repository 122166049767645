import { Box, Grid, Typography } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Grid container
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            {value === index && (
                <Grid item xs={12}>
                    {children}
                </Grid>
            )}
        </Grid>
    );
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}