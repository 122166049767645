import React, { useEffect, useState } from "react";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import {
  Grid,
  Typography,
  Box,
  Toolbar,
  CssBaseline,
  Divider,
  IconButton,
  Drawer as MuiDrawer,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { useAppContext } from "../../auth/appContext";

import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import useStyles from "./style";
import UserProfileButton from "./UserProfileButton";
import {
  GetDrawerItems,
  GetAppbarSX,
  GetAppbarElevation,
  GetPageTitle,
  GetBottomNavigationItems,
} from "./Instances";
import InnovationLogo from "../../assets/images/InnovationLogo.png";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 5px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DesktopDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  routes: any;
}

const MainDrawer: React.FC<Props> = ({ routes }) => {
  const location = useLocation();
  const theme = useTheme();
  const classes = useStyles();
  const { AppSettings } = useAppContext();
  const [open, setOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const contentMarginLeft = isMobile ? "0px" : "60px";

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  const drawerItems = (
    <>
      <DrawerHeader>
        <IconButton onClick={() => setOpen(false)}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      {/* ELEMENTOS DEL DRAWER */}
      {GetDrawerItems(AppSettings.Name, selectedRoute, setOpen)}
    </>
  );

  const navigationItems = (
    <>
      {/* ELEMENTOS DE BOTTOM NAVIGATION*/}
      {GetBottomNavigationItems(AppSettings.Name, selectedRoute, setOpen)}
    </>
  );

  return (
    <div className={classes.principalContainer}>
      <div className={classes.mainContainerBody}>
        <CssBaseline />
        <AppBar
          position="fixed"
          //  open={open}
          elevation={GetAppbarElevation(AppSettings.Name)}
          //fondo blanco fijo
          style={{ backgroundColor: "white" }}
        >
          <div
            //ponerle el color (sobre el blanco fijo)
            style={GetAppbarSX(AppSettings.Name)}
          >
            {/* BARRA SUPERIOR */}
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                onClick={() => setOpen(!open)}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                {!isMobile && (
                  <img
                    style={{ width: "128px" }}
                    src={AppSettings?.DesignSettings.Images.Logo}
                    alt="logo"
                  />
                )}
                <Typography fontWeight="bold" color="secondary" variant="h4">
                  {GetPageTitle(AppSettings.Name, selectedRoute)}
                </Typography>
                {!isMobile && <UserProfileButton />}
              </Grid>
            </Toolbar>
          </div>
        </AppBar>
        {/* DRAWER DE NAVEGACION */}
        {isMobile ? (
          <MuiDrawer
            variant="temporary"
            open={open}
            onClose={(_, reason) =>
              reason === "backdropClick" && setOpen(false)
            }
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                backgroundColor: theme.palette.primary.main,
              },
            }}
          >
            {drawerItems}
          </MuiDrawer>
        ) : (
          <DesktopDrawer
            variant={"permanent"}
            anchor="left"
            open={open}
            classes={{
              paper: classes.MuiDrawer,
            }}
          >
            {drawerItems}
          </DesktopDrawer>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 1, md: 3 },
            marginLeft: contentMarginLeft,
          }}
        >
          <DrawerHeader />
          {routes}
        </Box>
      </div>
      {/********** FOOTER BEGIN **********/}
      <div style={{ height: 210 }}>
        <div className={classes.footer}>
          <img
            hidden={window.location.pathname === "/"}
            className={classes.footerImg}
            src={InnovationLogo}
            alt="innovation_logo"
          />
          {/********** FOOTER END **********/}
        </div>
      </div>
      <div>
        {/* BOTTOM NAVIGATION */}
        {isMobile ? <>{navigationItems}</> : <></>}
      </div>
    </div>
  );
};

export const MainNavigation: React.FC<Props> = ({ routes }) => {
  const location = useLocation();
  const theme = useTheme();
  const { AppSettings } = useAppContext();
  const [open, setOpen] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<string>("");
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setSelectedRoute(location.pathname);
  }, [location]);

  const navigationItems = (
    <>
      {/* ELEMENTOS DE BOTTOM NAVIGATION*/}
      {GetBottomNavigationItems(AppSettings.Name, selectedRoute, setOpen)}
    </>
  );

  return (
    <div>
      {/* BOTTOM NAVIGATION */}
      {isMobile ? <>{navigationItems}</> : <></>}
    </div>
  );
};

export default MainDrawer;
