import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import React, { Suspense, useEffect, useState } from "react";
import { useAppContext } from "../../auth/appContext";
import withAuth from "../../auth/withAuth";
import BackdropComponent from "../../components/Backdrop";
import { firestore } from "../../firebase";
import { User } from "../../interfaces";
import * as EditProfiles from "./Instances";
import UsersAdmin from "./Instances/Default/usersTable";

const EditProfilesPage: React.FC = () => {
  const { AppSettings } = useAppContext();
  const [usersCollection, setUsersCollection] = useState<User[]>([]); //lista de usuarios (todos)
  const [editedUsers, setEditedUsers] = useState<any>({});
  const [saving, setSaving] = useState<boolean>(false);
  const [listResult, setListResult] = useState<any[]>([]);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [isReset, setIsReset] = useState<boolean>(false);

  useEffect(() => {
    getUsers();
    window.scrollTo(0, 0);
  }, [isReset]);

  // traer usuarios de base de datos
  const getUsers = async () => {
    const usersRef = collection(
      firestore,
      `Instances/${AppSettings.Name}/Users`
    );
    const usersQuery = await getDocs(usersRef);
    if (!usersQuery.empty) {
      const usersCollection = usersQuery.docs.map((u) => u.data() as User);
      setUsersCollection(usersCollection);
    }
  };

  // cuando se edita un usuario en la tabla
  const handleChange = (uid: string, LeaderEmail: string, LeaderName: string) => {
    let temp = { ...editedUsers };
    let tempUser = usersCollection.find((us) => us.UID === uid);

    // agregar el objeto data al usuario, y el usuario a editedUsers
    temp[uid] = {
      ...tempUser,
      Data: { ...tempUser!.Data, LeaderName, LeaderEmail },
    };

    setEditedUsers(temp);
  };

  // guardar los perfiles en la BD
  const saveProfiles = async () => {
    setSaving(true); //bandera
    let listRes: any[] = []; //para mostrar resultados

    const refUsers = collection(
      firestore,
      `Instances/${AppSettings.Name}/Users`
    );

    // por cada user de editedUsers
    for (const u of Object.values(editedUsers)) {
      let user = u as User;
      // resultado temporal
      let tempResult = {
        userID: user.UID ?? "",
        name: user.Name ?? "",
        correo: user.Email,
        enrroll: true,
        mensaje: "Perfil editado con éxito",
      };
      try {
        // documento del usuario
        const docRef = doc(refUsers, `${user.UID}`);
        let snapshot = await getDoc(docRef);

        // si el documento existe, que deberia...
        if (snapshot.exists()) {
          // actualizar el doc con el usuario editado
          await setDoc(docRef, user).then(() => {
            // agregar el resultado a lista de resultados
            listRes.push(tempResult);
          });
        }
      } catch (error) {
        // si hubo error, agregar el resultado negativo a lista de resultados
        listRes.push({
          ...tempResult,
          enrroll: false,
          mensaje: "Ocurrió un error al editar perfil",
        });
      }
    }
    setSaving(false);
    setListResult(listRes);
    setShowResults(true);
  };

  const reset = () => {
    setEditedUsers({});
    setSaving(false);
    setListResult([]);
    setShowResults(false);
    setIsReset(!isReset);
  };

  // if (usersCollection.length !== 0) {
  //   switch (AppSettings.Name) {
  //     default:
  //       return (
  //         <Suspense fallback={<div>Loading...</div>}>
  //           <EditProfiles.Default
  //             usersCollection={usersCollection}
  //             editedUsers={editedUsers}
  //             handleChange={handleChange}
  //             saveProfiles={saveProfiles}
  //             saving={saving}
  //             listResult={listResult}
  //             showResults={showResults}
  //             reset={reset}
  //           />
  //         </Suspense>
  //       );
  //   }
  // } else {
  //   return <BackdropComponent />;
  // }
  if (AppSettings.Config.LoginOptions && AppSettings.Config.LoginOptions.some(m => m.method.includes('password')))
    return <UsersAdmin />
  else
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <EditProfiles.Default
          usersCollection={usersCollection}
          editedUsers={editedUsers}
          handleChange={handleChange}
          saveProfiles={saveProfiles}
          saving={saving}
          listResult={listResult}
          showResults={showResults}
          reset={reset}
        />
      </Suspense>
    )
};
export default withAuth(EditProfilesPage);
