import { Suspense, useEffect } from "react";
import { useAppContext } from "../../auth/appContext";
import * as CalendarPageHub from "./Instances";

const CalendarPage = () => {
  const { AppSettings } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  switch (AppSettings.Name) {
    case "DHL":
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CalendarPageHub.DHL />
        </Suspense>
      );
    default:
      return (
        <Suspense fallback={<div>Loading...</div>}>
          <CalendarPageHub.Default />
        </Suspense>
      );
  }
};

export default CalendarPage;
