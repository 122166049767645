import React, { useEffect, useState } from "react";
import { Button, Divider, Grid, List, ListItemButton, ListItemText, SwipeableDrawer, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { QuizMaterial } from "../../../../interfaces";
import useStyles from "./style";
import GameQuiz from "./GameQuiz/Controller";
import PresentingTest from "../../../Study/Instances/Default/PresentingTest";
import { useDataContext } from "../../../../auth/dataContext";
import { Remove } from "@mui/icons-material";
import { useAppContext } from "../../../../auth/appContext";
import { theme } from "../../../SelectParticipants/Instances/DHL/Components/CustomTable/style";

interface Props {
  quizzes: QuizMaterial[];
  selectedQuiz: QuizMaterial | undefined;
  setSelectedQuiz: (activity: QuizMaterial | undefined) => void;
}

const RetaView: React.FC<Props> = ({
  quizzes,
  selectedQuiz,
  setSelectedQuiz,
}) => {
  /**
   * false para quiz tradicional
   * true para quiz interactivo
   */
  const formQuiz = true;
  const [reset, setReset] = useState<string>(new Date().toString());
  const [mobileOpen, setMobileOpen] = useState(false);
  const { AppSettings } = useAppContext();
  const { state } = useDataContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const assignment = state.assignments2![0];
  let currentModuleName = "";

  useEffect(() => {
    //Return to top
    window.scrollTo(0, 0);
  }, [selectedQuiz]);

  const classes = useStyles();

  if (quizzes.length === 0) {
    return (
      <div className={classes.root}>
        <Typography fontWeight="bold" variant="h5">
          No hay retas disponibles
        </Typography>
      </div>
    );
  }

  const Mobile = () => {
    return (
      <Grid container justifyContent='center' padding={1}>
        <Grid item xs={12} marginBottom={1}>
          {/* <IconButton
						onClick={handleDrawerToggle}
						edge="start"
						sx={{ color: '#000', ml: 0 }}
					>
						<Menu sx={{ color: '#000' }} />
					</IconButton> */}
          <Button variant="contained" fullWidth onClick={() => { setMobileOpen(true) }}>
            Ver actividades
          </Button>
        </Grid>
        <Grid item xs={12}>
          {/* <Box sx={{ display: 'flex', }}> */}
          <SwipeableDrawer
            onOpen={() => { setMobileOpen(true) }}
            variant="temporary"
            open={mobileOpen}
            onClose={() => { setMobileOpen(false) }}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{ display: 'block', '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 } }}
          >
            <Toolbar />
            <Divider />
            <List dense sx={{ overflowX: 'hidden', overflowY: 'auto', height:'800px' }}>
              {quizzes.map((quiz) => {
                let block = null;
                if (quiz.ModuleName !== currentModuleName) {
                  currentModuleName = quiz.ModuleName;
                  block = (
                    <div className={classes.blockDivider}>{currentModuleName}</div>
                  );
                }
                return (
                  <div key={quiz.ID}>
                    {block}
                    <ListItemButton
                      className={
                        selectedQuiz && quiz.ID === selectedQuiz.ID
                          ? classes.selectedMaterial
                          : classes.unselectedMaterial
                      }
                      onClick={() => {
                        setSelectedQuiz(quiz);
                        setReset(new Date().toString());
                        setMobileOpen(false);
                      }}
                    >
                      <ListItemText
                        primary={quiz.TopicName}
                      //  secondary={quiz.TopicName}
                      />
                      {
                        selectedQuiz && selectedQuiz.ID === quiz.ID ?
                          <Remove sx={{ color: 'red', marginRight: 0 }} />
                          :
                          null
                      }
                    </ListItemButton>
                  </div>
                );
              })}
            </List>
          </SwipeableDrawer>
          <Grid item xs={12} component="main">
            {selectedQuiz && formQuiz && (
              <PresentingTest
                key={reset}
                assignment={undefined}
                topicIndex={0}
                moduleIndex={0}
                minScore={AppSettings.Data?.minTestScore ?? 70}
                updateProgress={() => { }}
                back={() => setReset(new Date().toString())}
                handleReset={() => { }}
                examen={false}
                material={{
                  Name: "Quiz",
                  Type: "test",
                  Questions: [...selectedQuiz.Questions],
                }}
              />
            )}
            {selectedQuiz && !formQuiz && (
              <GameQuiz
                key={`${selectedQuiz.ModuleName}-${selectedQuiz.TopicName}`}
                examen={selectedQuiz!}
                setSelectedQuiz={setSelectedQuiz}
              />
            )}
            {!selectedQuiz && (
              <Typography variant="h6">Selecciona una reta</Typography>
            )}
          </Grid>
          {/* </Box> */}
        </Grid>
      </Grid >
    )
  }

  return (
    isMobile ?
      (
        Mobile()
      )
      :
      (
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <List dense>
              {quizzes.map((quiz) => {
                let block = null;
                if (quiz.ModuleName !== currentModuleName) {
                  currentModuleName = quiz.ModuleName;
                  block = (
                    <div className={classes.blockDivider}>{currentModuleName}</div>
                  );
                }
                return (
                  <div key={quiz.ID}>
                    {block}
                    <ListItemButton
                      className={
                        selectedQuiz && quiz.ID === selectedQuiz.ID
                          ? classes.selectedMaterial
                          : classes.unselectedMaterial
                      }
                      onClick={() => {
                        setSelectedQuiz(quiz);
                        setReset(new Date().toString());
                      }}
                    >
                      <ListItemText
                        primary={quiz.TopicName}
                      //  secondary={quiz.TopicName}
                      />
                      {
                        selectedQuiz && selectedQuiz.ID === quiz.ID ?
                          <Remove sx={{ color: 'red', marginRight: 0 }} />
                          :
                          null
                      }
                    </ListItemButton>
                  </div>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={9}>
            {selectedQuiz && formQuiz && (
              <PresentingTest
                key={reset}
                assignment={undefined}
                topicIndex={0}
                moduleIndex={0}
                minScore={AppSettings.Data?.minTestScore ?? 70}
                updateProgress={() => { }}
                back={() => setReset(new Date().toString())}
                handleReset={() => { }}
                examen={false}
                material={{
                  Name: "Quiz",
                  Type: "test",
                  Questions: [...selectedQuiz.Questions],
                }}
              />
            )}
            {selectedQuiz && !formQuiz && (
              <GameQuiz
                key={`${selectedQuiz.ModuleName}-${selectedQuiz.TopicName}`}
                examen={selectedQuiz!}
                setSelectedQuiz={setSelectedQuiz}
              />
            )}
            {!selectedQuiz && (
              <Typography variant="h6">Selecciona una reta</Typography>
            )}
          </Grid>
        </Grid>
      )
  );
};

export default RetaView;
