import { People } from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem, TableCell } from "@mui/material";
import { useState } from "react";

interface Props {
    Applicators: string[];
}
const DetailsCertif: React.FC<Props> = ({ Applicators }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Grid container>
            <Grid item xs={12} container alignItems='center'>
                <IconButton
                    onClick={handleClick}
                    size='small'
                    color='inherit'
                >
                    <People
                        fontSize="small"
                    />
                </IconButton>
                &emsp;
                {Applicators.length} Aplicadores
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {Applicators.map(
                        (val, key) => (
                            <MenuItem
                                key={key}
                                onClick={handleClose}>
                                {val}
                            </MenuItem>
                        ))}
                </Menu>
            </Grid>
        </Grid>
    )
}
export default DetailsCertif;