import { collection, getDocs } from "firebase/firestore";
import { displayOtherDate, displayShortDate } from "../Charts/utils";
import { firestore } from "../../firebase";
import { User } from "../../interfaces";
import { Course } from "../../interfaces/course";
import { StudyPlan } from "../../interfaces/studyplans";

/**
 * Obtener los nombres de los cursos pasados como params
 * @param ids IDs de los cursos
 * @param instance nombre de la instancia
 * @returns arreglo con el nombre de los cursos en el mismo orden que como se pasó los id
 */
const getCoursesName = async (ids: string[], instance: string) => {
  let cNames: string[] = [];

  const courseRef = collection(firestore, `Instances/${instance}/Courses`);

  const courseQuery = await getDocs(courseRef);
  if (!courseQuery.empty) {
    const courses = courseQuery.docs.map((u) => u.data() as Course);

    ids.forEach((id) => {
      cNames.push(courses.find((c) => c.ID === id)!.Name);
    });
  }

  return cNames;
};

/**
 * Obtener los nombres de los Planes de estudio pasados como params
 * @param ids IDs de los planes de estudio
 * @param instance nombre de la instancia
 * @returns arreglo con el nombre de los planes de estudio en el mismo orden que como se pasó los id
 */
const getSPNames = async (ids: string[], instance: string) => {
  let cNames: string[] = [];

  const courseRef = collection(firestore, `Instances/${instance}/StudyPlans`);

  const courseQuery = await getDocs(courseRef);
  if (!courseQuery.empty) {
    const courses = courseQuery.docs.map((u) => u.data() as StudyPlan);

    ids.forEach((id) => {
      cNames.push(courses.find((c) => c.ID === id)!.Name);
    });
  }

  return cNames;
};

/**
 * Obtener los nombres de los usuarios pasados como params
 * @param ids UIDS de los usuarios
 * @param instance nombre de la instancia
 * @returns arreglo con el nombre de los usuarios en el mismo orden que como se pasó los id
 */
const getUsersName = async (ids: string[], instance: string) => {
  let uNames: string[] = [];

  const courseRef = collection(firestore, `Instances/${instance}/Users`);

  const usersQuery = await getDocs(courseRef);
  if (!usersQuery.empty) {
    const users = usersQuery.docs.map((u) => u.data() as User);

    ids.forEach((uid) => {
      uNames.push(users.find((u) => u.UID === uid)!.Name!);
    });
  }

  return uNames;
};

/** Ordenar datos porque vienen random de firestore */
export const sortData = (data: any, numeric?: boolean) => {
  // almacenar en una estructura con orden
  var keyValues = [];
  for (var key in data) {
    keyValues.push([key, data[key]]);
  }
  if (numeric) {
    keyValues.sort(function compare(kv1, kv2) {
      return kv2[1] - kv1[1];
    });
  } else {
    // ordenar alfabeticamente
    keyValues.sort();
  }

  return keyValues;
};

/**
 * Armar la estructura del histograma (la serie de la gráfica son los últimos 30 días, y se hace un match para ver si en esos 30 días hay registros en BD)
 * @param data registros de DB
 * @param serieName nombre de la serie
 * @param cumulative opcional, si true, en los días donde no hay registro, se guarda el último valor disponible, si false, en los días sin registro se guarda 0
 */
export const armarHistograma: any = (
  data: any,
  serieName: string,
  cumulative?: boolean
) => {
  const { diasFormat, dias } = getUltimos30Dias();

  let categories = diasFormat;
  let d = [];

  if (!cumulative) {
    d = dias.map((d) => {
      if (data[d]) {
        return data[d];
      } else {
        return 0;
      }
    });
  } else {
    let last = 0;
    d = dias.map((d) => {
      if (data[d]) {
        last = data[d];
        return data[d];
      } else {
        return last;
      }
    });
  }

  let series = [{ name: serieName, data: d }];
  return { series, categories };
};

/**
 * Obtener los últimos 30 días para atrás, desde el día actual
 * @returns objeto con las últimas 30 fechas con diferentes formatos, para leer analytics y para mostrarlo en las gráficas
 */
export const getUltimos30Dias = () => {
  let fechasUltimoMes: Date[] = [];
  const now = new Date();
  now.setDate(now.getDate() - 31);

  const dateAhora = new Date();
  const nuevoDate = new Date();
  nuevoDate.setDate(nuevoDate.getDate() - 30);
  for (
    nuevoDate;
    nuevoDate <= dateAhora;
    nuevoDate.setDate(nuevoDate.getDate() + 1)
  ) {
    fechasUltimoMes.push(new Date(nuevoDate.setHours(0, 0, 0)));
  }

  /** Arreglo de los últimos 30 días en formato yyyy-MM-dd, así están guardados en BD los registros de UsersCreatedCount */
  const dias: any[] = [];
  /** Arreglo de los últimos 30 días en formato dd-MM-yyyy, así son más legibles para el usuario */
  const diasFormat: any[] = [];

  fechasUltimoMes.forEach((fecha) => {
    dias.push(displayShortDate(fecha.toDateString()));
    diasFormat.push(displayOtherDate(fecha.toDateString()));
  });

  return { dias, diasFormat };
};

/**
 * Obtiene el top10 descendiente de un mapa pasado [key: string] : number
 * @param instance nombre de la instancia
 * @param top mapa con los datos
 * @param type qué tipo de datos está recibiendo
 * @returns objeto para las charts con el top 10 de los datos
 */
export const getTop10 = async (
  instance: string,
  top: any,
  type: "Course" | "User" | "StudyPlan"
) => {
  let top10: any[][] = sortData(top, true).slice(0, 10);
  let labels: any = {};

  switch (type) {
    case "Course":
      labels = await getCoursesName(
        Object.values(top10).map((c) => c[0]),
        instance
      );
      break;

    case "User":
      labels = await getUsersName(
        Object.values(top10).map((c) => c[0]),
        instance
      );
      break;

    case "StudyPlan":
      labels = await getSPNames(
        Object.values(top10).map((c) => c[0]),
        instance
      );
      break;
  }

  let series = Object.values(top10).map((v) => v[1]);

  return { labels, series };
};
