
import { initializeApp } from "firebase/app";
import { getFunctions } from "firebase/functions";
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from 'firebase/database';

let authDomain = "virtualuniversity-b7176.firebaseapp.com"

if (!window.location.host.includes('localhost')) {
	authDomain = window.location.host
}

const firebaseConfig = {
	apiKey: "AIzaSyDT-4yjCiyKOO72GJ2-ug850qyEAJiM1xQ",
	authDomain: authDomain,
	databaseURL: "https://virtualuniversity-b7176.firebaseio.com",
	projectId: "virtualuniversity-b7176",
	storageBucket: "virtualuniversity-b7176.appspot.com",
	messagingSenderId: "306933306612",
	appId: "1:306933306612:web:1868edf02b55be1e903aa1",
};

export const fbApp = initializeApp(firebaseConfig);
export const fbFunctions = getFunctions(fbApp);
export const firestore = getFirestore(fbApp);
export const fbAuth = getAuth(fbApp);
export const storage = getStorage(fbApp);
export const db = getDatabase(fbApp);
setPersistence(fbAuth, browserLocalPersistence);
