import React, { useState } from 'react';
import { Grid, Typography, Paper, Box, Button, Fade, ListItem, ListItemIcon, ListItemText, List } from "@mui/material";
import classNames from "classnames";
import { StudyTip } from "../../../../interfaces/course";
import { Contact } from "../../../../interfaces/index";
import { doc, updateDoc } from "firebase/firestore";
import { useDataContext } from '../../../../auth/dataContext';
import { Container, Draggable } from 'react-smooth-dnd';
import { DragHandle } from '@mui/icons-material';
import { arrayMoveImmutable } from 'array-move';
import { firestore } from '../../../../firebase';
import { useAppContext } from '../../../../auth/appContext';
import ContactCard from "../../ContactCard";
import useStyles from "./style";
import Carousel from '../../Instances/DHL/Carousel'
import EditAnnonuncements from './EditAnnouncements';

const CertificationsTable = React.lazy(() => import("../../Instances/DHL/CertificationsTable"));

interface Props {
	studyTips: StudyTip[];
	instructors: Contact[];
}

const AnnouncementsView: React.FC<Props> = ({ studyTips, instructors }) => {
	const classes = useStyles();
	const [edit, setEdit] = useState<boolean>(false);
	const { state } = useDataContext();
	const { AppSettings } = useAppContext();

	const updater = (removedIndex: number, addedIndex: number) => {
		let array = arrayMoveImmutable(instructors, removedIndex, addedIndex);

		array.forEach((item, index) => {
			const ref = doc(firestore, `Instances/${AppSettings.Name}/Instructors/${item.ID}`);
			updateDoc(ref, { Priority: index })
		})
	}

	if (edit)
		return (
			<EditAnnonuncements
				setEdit={setEdit}
				fade={edit}
				instructors={instructors}
			/>
		)
	else
		return (
			<Fade in={!edit} className={classes.root}>
				<Grid container className={classes.containerItemTipsEstudio}>
					<Grid item xs={12}>
						{(state.user.Data?.isAdmin || state.user.Data?.isTester) &&
							<Button
								variant="contained"
								color="secondary"
								sx={{ mb: 2 }}
								onClick={() => { setEdit(true) }}
							>
								Editar panel
							</Button>
						}
					</Grid>
					<Grid item xs={12} sx={{ mb: { xs: 0, md: 2 } }}>
						<Paper>
							<Typography
								variant='h6'
								className={classNames(
									classes.typographyTitulosTarjetas,
									classes.typographyTitulosColor
								)}
								style={{ fontWeight: "bolder" }}>
								Tips de Estudio
							</Typography>
							<div className={classes.divElementsTipsEstudio}>
								{studyTips.length > 0 ? (
									<Carousel
										studyTips={studyTips}
									/>
								) : (
									<Typography
										variant='h6'
									>
										No hay Tips que mostrar
									</Typography>
								)}
							</div>
						</Paper>
					</Grid>
					<Grid container columnSpacing={2}>
						<Grid item xs={12} md={5}>
							<Paper>
								<Typography
									variant='h6'
									className={classNames(
										classes.typographyTitulosTarjetas,
										classes.typographyTitulosColor
									)}
									style={{ fontWeight: "bolder" }}>
									Directorio Entrenamiento OPS
								</Typography>
								<Box sx={{ p: { xs: 0, md: 2 } }}>
									<div className={classes.divElementsOPS}>
										{instructors && instructors.length > 0 ?
											state.user.Data?.isAdmin || state.user.Data?.isTester ?
												<List dense disablePadding>
													<Container onDrop={(e) => { updater(e.removedIndex!, e.addedIndex!) }} lockAxis="y">
														{instructors.map((contact, index) => (
															<Draggable key={index}>
																<ListItem>
																	<ListItemIcon>
																		<DragHandle style={{ cursor: "ns-resize" }} />
																	</ListItemIcon>
																	<ListItemText primary={<ContactCard item={contact} />} />
																</ListItem>
															</Draggable>
														))}
													</Container>
												</List>
												:
												instructors.map((contact, index) => (
													<ContactCard item={contact} key={index} />
												))
											: (
												<>No hay Contactos que mostrar</>
											)}
									</div>
								</Box>
							</Paper>
						</Grid>
						<Grid item xs={12} md={7} sx={{ marginTop: { xs: 2, md: 0 } }}>
							<Paper>
								<Typography
									variant='h6'
									className={classNames(
										classes.typographyTitulosTarjetas,
										classes.typographyTitulosColor
									)}
									style={{ fontWeight: "bolder" }}>
									Fechas de certificación
								</Typography>
								<Box p={2}>
									<React.Suspense
										fallback={
											<Typography>
												Cargando fechas...
											</Typography>
										}
									>
										<CertificationsTable />
									</React.Suspense>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Fade >
		)
};

export default AnnouncementsView;
